/* eslint-disable */
import React from 'react'
import { Button, Table, Tabs, Menu, message } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'
import EvenModal from 'components/lg/modal'

import UserForm from './components/userForm'
import ResetPasswordForm from './components/resetPasswordForm'

import { confirmMessage } from 'utils/index'
import { PlusOutlined } from '@ant-design/icons'
import { modifyUser, fetchSystemUsers, addUser, userResetPassword, fetchUserDetail } from 'api/set/user'
import { fetchClients, fetchClientDetail } from 'api/client'
import { base64UrlToBlob, confirmSingleDelete, noUndefined, } from 'utils'
import { validPhone, validEmail } from 'utils/validate'
import request from 'utils/request'

import './index.scss'

const { TabPane } = Tabs

export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      resetPasswordForm: {},

      modalVisible: false,
      modalTitle: '',
      modalContent: {
        addMark: false,
        markDetail: false,
        resetPassword: false
      },

      selectedRowKeys: [],

      currentTab: 'bd',

      account: JSON.parse(localStorage.getItem('authInfo'))?.token?.account
    }
  }

  componentDidMount() {
    this.getList()
  }

  listQuery = () => {
    return {
      page: `${this.state.currentPage}`,
      page_size: `${this.state.pageSize}`,
      search: `${this.state.searchTxt}`,
    }
  }
  getList = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isTableLoading: true
      })
      let fetchFn
      if (this.state.currentTab === 'bd') {
        fetchFn = fetchSystemUsers
      } else {
        fetchFn = fetchClients
      }
      fetchFn(this.listQuery()).then(({ data }) => {
        let { results, count } = data
        for (const item of results) {
          noUndefined(item)
        }
        this.setState({
          isTableLoading: false,
          table: results,
          allCount: count,
        })
        resolve()
      })
    })
  }
  getDetail = () => {
    return new Promise((resolve, reject) => {
      let fn
      const { currentTab, currentId } = this.state
      if (currentTab === 'bd') {
        fn = fetchUserDetail
      } else {
        fn = fetchClientDetail
      }
      fn(currentId).then(response => {
        this.setState({
          detailForm: response.data
        })
        resolve()
      })
    })
  }

  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    console.log(name, current, pageSize)
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page, pageSize) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }
  clickAddBtn = () => {
    this.initModal()
    this.initForm()

    this.setState({
      modalVisible: true,
      modalTitle: '新增用户',
      modalContent: {
        addMark: true
      },
    })
  }
  initModal = () => {
    this.setState({
      modalContent: {
        addMark: false,
        markDetail: false,
        resetPassword: false
      },
    })
  }
  initForm = () => {
    this.setState({
      addForm: {},
      detailForm: {},
    })
  }
  submitEdit(value) {
    const key = Object.keys(value)[0]
    const val = Object.values(value)[0]
    if (key === 'phone') {
      if (!validPhone(val)) return
    }
    if (key === 'email') {
      if (!validEmail(val)) return
    }
    if (key === 'avatar') {
      value = new FormData()
      value.append('avatar', base64UrlToBlob(val))
    }
    modifyUser(this.state.currentId, value).then(response => {
      this.getList()
      message.success('修改成功！')
      this.setState({ modalVisible: false })
    }, error => {
      console.log(error.response)
    })
  }
  submitForm = (name, value) => {
    console.log(value)
    switch (name) {
      case 'add':
        let submitData = value
        addUser(submitData).then(response => {
          this.getList()
          message.success('创建成功！')
          this.setState({
            modalVisible: false
          })
        }, error => {
          console.log(error.response)
        })
        break
      case 'detail':
        this.submitEdit(value)
        break
      case 'resetPasswordForm':
        userResetPassword(this.state.currentId, { new_password: value.password }).then(response => {
          message.success('重置成功！')
          this.getList()
          this.setState({
            modalVisible: false
          })
        }, error => {
          console.log(error.response)
        })
        break
      default:
    }
  }
  cancelForm = (name, value) => {
    this.setState({
      modalVisible: false
    })
  }
  cancelModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  submitModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  clickDropdown = (name, text, record, e) => {
    e.stopPropagation()

    this.setState({
      currentId: text.id,
    })
  }
  deleteUser(id) {
    let url = this.state.currentTab === 'bd' ? 'users/' : 'clients/'
    confirmSingleDelete(this, url, id)
  }

  clickDropdownItem = (value, id) => {
    let func = (url, method, msg) => {
      return () => request({ url, method }).then(response => {
        if (response.status === 200)
          message.success(msg)
        this.setState({
          currentPage: 1
        }, () => {
          this.getList()
        })
      })
    }
    let url = `users/${id}/`
    if (this.state.currentTab === 'wx')
      url = `clients/${id}/`
    switch (value) {
      case 'forbid':
        confirmMessage(func(url, 'DELETE', '已禁用！'), '确定禁用该用户？', '已取消禁用')
        break
      case 'active':
        confirmMessage(func(`${url}active/`, 'PUT', '已激活！'), '确定激活该用户？', '已取消激活')
        break
      case 'resetPassword':
        this.setState({
          modalVisible: true,
          currentId: id
        })
        this.initModal()
        this.setState({
          modalTitle: '重置密码',
          modalContent: {
            resetPassword: true
          }
        })
        break
      default:
    }
  }
  checkRow = (id) => {
    this.setState({
      currentId: id,
      modalVisible: true
    }, () => {
      this.getDetail().then(() => {
        this.setState({
          modalContent: {
            markDetail: true
          }
        })
      })
    })
    this.initModal()
    this.setState({
      modalTitle: '用户详情'
    })
  }

  clickTab(v) {
    this.setState({
      currentTab: v,
      currentPage: 1,
      pageSize: null,
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
    }, () => {
      this.getList()
    })
  }

  renderActiveBtn(text, record) {
    const { is_deleted } = record
    return <span>
      {!is_deleted &&
        <a
          className='warning'
          onClick={() => this.clickDropdownItem('forbid', text)}>
          禁用
        </a>}
      {is_deleted &&
        <a onClick={() => this.clickDropdownItem('active', text)}>
          激活
        </a>}
    </span>
  }

  render() {
    const { currentTab, account } = this.state
    const { clickTab } = this

    const bdUsersColumns = [
      {
        title: '用户账号',
        dataIndex: 'account',
      },
      {
        title: '用户名',
        dataIndex: 'nickname',
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: (text, record) => (
          <div className='operation-column'>
            <a className='' onClick={() => this.checkRow(text)}>编辑</a>
            {account === 'admin' && <a onClick={() => this.clickDropdownItem('resetPassword', text)}>重置密码</a>}
            {this.renderActiveBtn(text, record)}
          </div>
        ),
      },
    ]
    const wxUsersColumns = [
      {
        title: '手机号码',
        dataIndex: 'phone_number',
      },
      {
        title: '用户名',
        dataIndex: 'nickname',
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: (text, record) => (
          <div className='operation-column'>
            {this.renderActiveBtn(text, record)}
          </div>
        ),
      },
    ]

    // 切换不同用户类型
    let currentColumns
    let addBtnClass = ''
    if (currentTab === 'bd') {
      currentColumns = bdUsersColumns
      addBtnClass = ''
    } else {
      addBtnClass = 'visibility-hidden'
      currentColumns = wxUsersColumns
    }

    return <div className='mainWrapper user-root'>
      <Tabs activeKey={currentTab} onChange={clickTab.bind(this)}>
        <TabPane tab="后台用户" key="bd" />
        <TabPane tab="小程序用户" key="wx" />
      </Tabs>
      <SearchWithWrapper
        loading={this.state.isSearchBtnLoading}
        value={this.state.searchTxt}
        onChange={this.onSearchChange}
      >
        <Button
          className={addBtnClass}
          type="primary"
          size='middle'
          icon={<PlusOutlined />}
          onClick={() => this.clickAddBtn(this.state.currentTableName)}
        >
          新增
        </Button>
      </SearchWithWrapper>
      <Table
        tableLayout='fixed'
        loading={this.state.isTableLoading}
        rowKey='id'
        dataSource={this.state.table}
        columns={currentColumns}
        pagination={false}
        rowClassName={(record) => record.is_deleted && 'disabled-user'}
      />
      <EvenPagination
        current={this.state.currentPage}
        total={this.state.allCount}
        onShowSizeChange={this.onShowSizeChange}
        onPageChange={this.onPageChange}
        name={this.state.currentTableName}
      />

      <EvenModal
        className='user-modal'
        title={this.state.modalTitle}
        visible={this.state.modalVisible}
        cancel={this.cancelModal}
        submit={this.submitModal}
        slot={
          <div>
            {this.state.modalContent.addMark &&
              <UserForm
                name={'add'}
                form={this.state.addForm}
                submitForm={(value) => { this.submitForm('add', value) }}
                cancelForm={this.cancelForm}
              />}
            {this.state.modalContent.markDetail &&
              <UserForm
                name={'detail'}
                form={this.state.detailForm}
                submitForm={(value) => { this.submitForm('detail', value) }}
                cancelForm={this.cancelForm}
              />}
            {this.state.modalContent.resetPassword &&
              <ResetPasswordForm
                name={'resetPassword'}
                form={this.state.resetPasswordForm}
                submitForm={(value) => { this.submitForm('resetPasswordForm', value) }}
                cancelForm={this.cancelForm}
              />}
          </div>
        }
      />
    </div>
  }
}
