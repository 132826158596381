import request from 'utils/request'

const mainUrl = 'orders/'

export function fetchOrders(params) {
  return request({
    url: mainUrl,
    method: 'GET',
    params
  })
}

export function fetchExpress(id) {
  return request({ url: mainUrl + id + '/express/' })
}

export function exportOrders(ids) {
  return request({
    url: mainUrl + 'export/',
    method: 'POST',
    responseType: 'blob',
    data: { ids }
  })
}