import axios from 'axios'
import { message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
// import store from "../store";
import { baseUrl } from "utils/index"
import { requestTimeout } from "utils/constant"
import React from "react"
import store from "../store"
// let storeObj = store.getState()
const CancelToken = axios.CancelToken
const source = CancelToken.source()
let isTokenExpired = false
const service = axios.create({
  baseURL: baseUrl, // api 的 base_url
  timeout: requestTimeout, // 请求超时时间
  headers: { 'Content-Type': 'application/json' },
  cancelToken: source.token,
})
// request 拦截器
service.interceptors.request.use(
  config => {
    const authInfo = JSON.parse(localStorage.getItem('authInfo'))
    if (authInfo) {
      config.headers.Authorization = authInfo.token.token
    }

    return config
  },
  error => {
    return Promise.reject(error)
  })

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.success === false) {
      let config = {
        content: res.messages,
        top: 100,
        duration: 5,
      }
      message.error(config)
      return Promise.reject('error')
    } else {
      return response
    }
  },
  error => {
    console.log(error.response) // for debug
    // if (!error.response) return

    if (error.response) {
      if (error.request.responseType === 'arraybuffer') {
        return Promise.reject(error)
      }

      const { pathname, origin } = window.location
      const { status, data } = error.response
      switch (status) {
        case 401:
          source.cancel('Operation canceled by the user.')
          if (!isTokenExpired) {
            let secondsToGo = 5
            let modal = Modal.confirm({
              title: '确定登出',
              icon: <ExclamationCircleOutlined />,
              content: `会话已过期，${secondsToGo}秒后返回到登录页`,
              autoFocusButton: 'ok',
              okText: '重新登录',
              cancelText: '取消',
              onOk() {
                localStorage.removeItem('authInfo')
                store.dispatch({ type: 'signOut' })
                if (pathname !== '/login') {
                  window.location.replace(origin + '/login')
                  window.location.reload() // 为了重新实例化vue-router对象 避免bug
                }
              },
              onCancel() {

              },
            })
            setTimeout(() => {
              modal.destroy()

              localStorage.removeItem('authInfo')
              store.dispatch({ type: 'signOut' })
              if (pathname !== '/login') {
                window.location.replace(origin + '/login')
                window.location.reload() // 为了重新实例化vue-router对象 避免bug
              }
            }, secondsToGo * 1000)

            isTokenExpired = true

          }

          /*if (pathname !== '/login') {
            window.location.replace(origin + '/login')
          }*/
          break
        case 403:
          // 跳转到登陆页面
          localStorage.clear()
          window.location.replace(origin + '/login')
          break
        case 500:
          message.error('服务端错误')
          break
        default:
          console.log(data)
          if (typeof data === 'string') {
            message.error(data)
          } else {
            message.error(data.msg)
          }
      }
    }
    return Promise.reject(error)
  }
)

export default service
