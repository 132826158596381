import request from '../../utils/request.js'

export function fetchLogsList(query) {
  return request({
    url: 'logs/',
    method: 'GET',
    params: query,
  })
}
export function fetchLogDetail(id) {
  return request({
    url: `action_logs/${id}/`,
    method: 'get',
  })
}
export function fetchActionTypesList() {
  return request({
    url: `action_logs/list_configs/`,
    method: 'get',
  })
}