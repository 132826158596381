import { Button, Col, Form, Input, message, Row, Select } from "antd"
import { verifySample } from "api/sample"
import React from "react"

const { Option } = Select

export default function (props) {
  const { results, id } = props.sampleDetail
  console.log(results)
  const handleSubmit = values => {
    console.log(values)
    const submitArr = []
    for (const iterator of results) {
      const { id } = iterator
      submitArr.push({
        id,
        name: values['name' + id],
        result: values['result' + id]
      })
    }
    verifySample(id, submitArr).then(({ data }) => {
      message.success(data)
      props.submitModal()
    })
  }

  const renderResults = (project, result, id) => <Row key={id}>
    <Col span={10}>
      <Form.Item label='检测项目' name={'name' + id} initialValue={project}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col span={14}>
      <Form.Item label='检测结果' name={'result' + id} initialValue={result}>
        <Select>
          <Option value='阴性'>阴性</Option>
          <Option value='阳性'>阳性</Option>
        </Select>
      </Form.Item>
    </Col>
  </Row>


  return <Form onFinish={handleSubmit}>
    {results && results.map(i => renderResults(i.name, i.result, i.id))}
    <Form.Item style={{ paddingLeft: '7em' }}>
      <Button htmlType="submit" type="primary">提交</Button>
    </Form.Item>
  </Form>
}