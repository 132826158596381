import request from 'utils/request'

const mainUrl = 'users/'

export function fetchSystemUsers(query) {
  return request({
    url: `${mainUrl}`,
    method: 'get',
    params: query
  })
}
export function fetchUserDetail(id) {
  return request({
    url: `${mainUrl}${id}/`,
    method: 'get'
  })
}
export function fetchBatchDataList(query) {
  return request({
    url: 'batch_datas/',
    method: 'get',
    params: query
  })
}
export function addUser(data) {
  return request({
    url: `${mainUrl}`,
    method: 'POST',
    data
  })
}
export function modifyUser(id, data) {
  return request({
    url: `${mainUrl}${id}/`,
    method: 'put',
    data
  })
}
export function modifyUserByPatch(id, data) {
  return request({
    url: `${mainUrl}${id}/`,
    method: 'PATCH',
    data
  })
}
export function deleteUser(id) {
  return request({
    url: `${mainUrl}${id}/`,
    method: 'delete',
  })
}
export function bulkDeleteUsers(data) {
  return request({
    url: `${mainUrl}bulk_delete/`,
    method: 'POST',
    data
  })
}
export function userActivation(id, data) {
  return request({
    url: `${mainUrl}${id}/is_active/`,
    method: 'POST',
    data
  })
}
export function userResetPassword(id, data) {
  return request({
    url: `${mainUrl}${id}/password/reset/`,
    method: 'PUT',
    data
  })
}