import request from 'utils/request'

export function fetchProducts(params) {
  return request({
    url: 'products/',
    method: 'GET',
    params
  })
}

export function fetchProductDetail(id) {
  return request({
    url: `products/${id}/`,
    method: 'GET',
  })
}

export function putProductDetail(id, data) {
  request({
    url: `products/${id}`,
    method: 'PUT',
    data
  })
}