/* eslint-disable */
import React from 'react'
import { Row, Col, Modal, Button, Table, Form, Input, Upload, InputNumber, message } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'

import request from 'utils/request'
import { PlusOutlined } from '@ant-design/icons'

import { confirmSingleDelete, noUndefined, } from 'utils'

const mainUrl = 'projects/'

export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      coverFileList: [],
      loading: false,

      detailFileList: [],

      modalVisible: false,
      modalTitle: '新增项目',
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    this.getList()
  }


  listQuery = () => {
    return {
      page: `${this.state.currentPage}`,
      page_size: `${this.state.pageSize}`,
      search: `${this.state.searchTxt}`,
    }
  }
  getList = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        isTableLoading: true
      })
      request({
        url: 'products/' + this.props.id + '/projects/',
        method: 'GET',
        params: this.listQuery()
      }).then(({ data }) => {
        this.setState({
          isTableLoading: false,
          table: data,
          allCount: data.length,
        })
        resolve()
      })
    })
  }
  getDetail = () => {
    return new Promise((resolve, reject) => {
      request({ url: mainUrl + this.state.currentId + '/', method: 'GET' })
        .then(({ data }) => {
          this.formRef.current.setFieldsValue(data)
          resolve()
        })
    })
  }
  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page, pageSize) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }
  clickAddBtn = () => {
    this.setState({
      modalVisible: true,
      modalTitle: '新增项目',
      coverFileList: [],
      detailFileList: [],
    })
  }
  submitForm = values => {
    let url = mainUrl
    let method = 'POST'
    const { currentId, modalTitle, } = this.state
    if (modalTitle === '项目详情') {
      url += currentId + '/'
      method = 'PUT'
    }
    values.product_id = this.props.id
    request({ url, method, data: values })
      .then(({ data }) => {
        message.success(data)
        this.getList()
        this.cancelModal()
      })
  }
  cancelModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  checkRow = (id) => {
    this.setState({
      currentId: id,
      modalTitle: '项目详情',
      modalVisible: true
    }, () => {
      this.getDetail()
    })
  }

  tabClick = (key, event) => {
    console.log(key, event)
    this.setState({
      currentTableName: key,
      currentPage: 1,
      pageSize: null,
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
    }, () => {
      this.getList()
    })
  }

  render() {
    const { modalTitle, modalVisible, loading } = this.state
    const { checkRow, cancelModal, submitForm, clickAddBtn } = this

    const columns = [
      { title: '项目名称', dataIndex: 'name', },
      { title: '参考区间', dataIndex: 'reference', },
      {
        title: '操作',
        dataIndex: 'id',
        render: text => (
          <div className='operation-column'>
            <a
              onClick={() => checkRow(text)}
              style={{ marginRight: '1em' }}
            >编辑</a>
            <a
              className='warning'
              onClick={() => confirmSingleDelete(this, mainUrl, text)}
            >删除</a>
          </div>
        ),
      },
    ]

    return <div className='mainWrapper plan-root'>
      <SearchWithWrapper
        loading={this.state.isSearchBtnLoading}
        value={this.state.searchTxt}
        onChange={this.onSearchChange}
        slot={
          <Button
            type="primary"
            size='middle'
            icon={<PlusOutlined />}
            onClick={() => clickAddBtn(this.state.currentTableName)}
          >
            新增
          </Button>
        } />
      <Table
        tableLayout='fixed'
        loading={this.state.isTableLoading}
        rowKey='id'
        dataSource={this.state.table}
        columns={columns}
        pagination={false}
      />
      <EvenPagination
        current={this.state.currentPage}
        total={this.state.allCount}
        onShowSizeChange={this.onShowSizeChange}
        onPageChange={this.onPageChange}
        name={this.state.currentTableName}
      />

      <Modal
        title={modalTitle}
        visible={modalVisible}
        width='70%'
        footer={null}
        onCancel={cancelModal}
        destroyOnClose
      >
        <Form
          name="plan"
          ref={this.formRef}
          onFinish={submitForm}
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label="项目名称"
            name="name"
            rules={[{ required: true, message: '请输入项目名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="参考区间"
            name="reference"
            rules={[{ required: true, message: '请输入参考区间' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="疾病说明"
            name="content"
            rules={[{ required: true, message: '请输入疾病说明' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  }
}
