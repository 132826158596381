import React from 'react'
import { Button, Input, Form, message } from 'antd'

import request from 'utils/request'

export default function (props) {
  const { url, serial_number, status } = props

  const onFinish = (values) => {
    request({
      url,
      method: 'PUT',
      data: { record: values.record, status }
    }).then(({ data }) => {
      message.success(data)
      props.submit()
    })
  }

  return <Form
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={onFinish}
  >
    <Form.Item
      label='条码号'
      name='serial_number'
      initialValue={serial_number}
    >
      <Input disabled />
    </Form.Item>
    <Form.Item label='备注' name='record'>
      <Input />
    </Form.Item>
    <Form.Item wrapperCol={{ offset: 4 }}>
      <Button type='primary' htmlType="submit">
        {status === 60 ? '入库' : '修改'}
      </Button>
    </Form.Item>
  </Form>
}