/* eslint-disable */
import React from 'react'
import { Row, Col, Modal, Button, Table, Form, Input, Upload, InputNumber, message } from 'antd'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'
import Project from './components/project/index'

import request from 'utils/request'
import { confirmSingleDelete, noUndefined, } from 'utils'
import { fetchProductDetail, fetchProducts } from 'api/product'


let coverUrl = ''
let detailImgUrls = []
const mainUrl = 'products/'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.results))
  reader.readAsDataURL(img)
}

export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: null,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      coverFileList: [],
      loading: false,

      detailFileList: [],

      modalVisible: false,
      modalTitle: '新增套餐',

      previewVisible: false,
      previewImage: '',
      previewTitle: '',
    }
  }

  formRef = React.createRef()

  componentDidMount() {
    this.getList()
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  };

  handleFilesChange = (param, v) => {
    const { fileList, file } = v
    const { response } = file
    const { length } = fileList
    this.setState({ [param]: fileList })
    if (param === 'coverFileList') {
      if (length) {
        coverUrl = response
        return
      }
      coverUrl = ''
    } else {
      if (!length) {
        detailImgUrls = []
        return
      }
      if (typeof detailImgUrls === 'string')
        detailImgUrls = detailImgUrls.split(',')
      if (file.status === 'removed') { // 删除图片
        const index = detailImgUrls.indexOf(file.url)
        detailImgUrls.splice(index, 1)
      } else {
        response && detailImgUrls.push(response)
      }
    }
  }

  listQuery = () => {
    return {
      page: `${this.state.currentPage}`,
      page_size: `${this.state.pageSize}`,
      search: `${this.state.searchTxt}`,
    }
  }
  getList = () => {
    return new Promise(resolve => {
      this.setState({
        isTableLoading: true
      })
        fetchProducts(this.listQuery())
        .then(({ data }) => {
          let { results, count } = data
          for (const item of results) {
            item.price = item.price / 100
            noUndefined(item)
          }
          this.setState({
            isTableLoading: false,
            table: results,
            allCount: count,
          })
          resolve()
        })
    })
  }
  getDetail = () => {
      fetchProductDetail(this.state.currentId)
        .then(({ data }) => {
          const { content, cover } = data
          coverUrl = cover
          detailImgUrls = content
          const tempArr = content.split(',')
          const detailFileList = tempArr.map(e => ({ url: e }))
          this.setState({
            coverFileList: [{ url: cover }],
            detailFileList
          })
          this.formRef.current.setFieldsValue(data)
    })
  }
  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }
  clickAddBtn = () => {
    this.setState({
      modalVisible: true,
      modalTitle: '新增套餐',
      coverFileList: [],
      detailFileList: [],
    })
  }
  submitForm = values => {
    let url = mainUrl
    let method = 'POST'
    const { currentId, modalTitle, } = this.state
    if (modalTitle === '套餐详情') {
      url += currentId + '/'
      method = 'PUT'
    }
    values.cover = coverUrl
    if (typeof detailImgUrls === 'string') {
      values.content = detailImgUrls
    } else {
      values.content = detailImgUrls.join()
    }
    request({ url, method, data: values })
      .then(({ data }) => {
        message.success(data)
        this.getList()
        this.cancelModal()
      })
  }
  cancelModal = () => {
    this.setState({
      modalVisible: false
    })
    coverUrl = ''
    detailImgUrls = []
  }
  checkRow = (id, modalTitle) => {
    this.setState({
      currentId: id,
      modalTitle,
      modalVisible: true
    }, () => {
      if (modalTitle === '套餐详情')
        this.getDetail()
    })
  }

  tabClick = (key, event) => {
    console.log(key, event)
    this.setState({
      currentTableName: key,
      currentPage: 1,
      pageSize: null,
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
    }, () => {
      this.getList()
    })
  }

  render() {
    const { modalTitle, modalVisible, loading, currentId, table, isTableLoading, previewVisible, previewImage,  previewTitle } = this.state
    const { checkRow, cancelModal, submitForm, clickAddBtn, handleFilesChange } = this

    const columns = [
      { title: '项目名称', dataIndex: 'name', },
      {
        title: '价格',
        dataIndex: 'price',
        render:text=>text+'元'
      },
      { title: '排序', dataIndex: 'value', },
      {
        title: '操作',
        dataIndex: 'id',
        render: text => (
          <div className='operation-column'>
            <a onClick={() => checkRow(text, '套餐详情')}>详情</a>
            <a onClick={() => checkRow(text, '检测项目')}>检测项目</a>
            <a
              className='warning'
              onClick={() => confirmSingleDelete(this, mainUrl, text)}
            >删除</a>
          </div>
        ),
      },
    ]

    const uploadButton = <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传</div>
    </div>

    const renderUpload = (fileListName) => {
      const TOKEN = JSON.parse(localStorage.getItem('authInfo')).token.token
      const action = request.defaults.baseURL + 'products/upload/'
      const fileList = this.state[fileListName]
      const flag = fileListName === 'coverFileList' ? fileList.length : fileList.length >= 8

      return <Upload
        listType="picture-card"
        accept='.png,.jpg'
        fileList={fileList}
        action={action}
        headers={{ authorization: TOKEN }}
        onChange={(v) => handleFilesChange(fileListName, v)}
        onPreview={this.handlePreview}
      >
        {flag ? null : uploadButton}
      </Upload>
    }

    return <div className='mainWrapper plan-root'>
      <SearchWithWrapper
        loading={this.state.isSearchBtnLoading}
        value={this.state.searchTxt}
        onChange={this.onSearchChange}
        slot={
          <Button
            type="primary"
            size='middle'
            icon={<PlusOutlined />}
            onClick={() => clickAddBtn(this.state.currentTableName)}
          >
            新增
          </Button>
        } />
      <Table
        tableLayout='fixed'
        loading={isTableLoading}
        rowKey='id'
        dataSource={table}
        columns={columns}
        pagination={false}
      />
      <EvenPagination
        current={this.state.currentPage}
        total={this.state.allCount}
        onShowSizeChange={this.onShowSizeChange}
        onPageChange={this.onPageChange}
        name={this.state.currentTableName}
      />

      <Modal
        title={modalTitle}
        visible={modalVisible}
        width='70%'
        footer={null}
        onCancel={cancelModal}
        destroyOnClose
      >
        {(modalTitle === '套餐详情' || modalTitle === '新增套餐') && <Form
          name="plan"
          ref={this.formRef}
          onFinish={submitForm}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                label="套餐名称"
                name="name"
                labelCol={{ span: 9 }}
                rules={[{ required: true, message: '请输入套餐名称' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="价格"
                name="price"
                rules={[{ required: true, message: '请输入价格' }]}
              >
                <InputNumber addonAfter='分' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="排序"
                name="value"
                rules={[{ required: true, message: '请输入排序' }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label='封面'
            name='cover'
            rules={[() => ({
              validator() {
                if (coverUrl) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('请上传封面图片'))
              }
            })]}
            labelCol={{ span: 3 }}
          >
            {renderUpload('coverFileList')}
          </Form.Item>
          <Form.Item
            label='商品详情'
            name='content'
            rules={[() => ({
              validator() {
                if (detailImgUrls.length) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('请上传详情图片'))
              }
            })]}
            labelCol={{ span: 3 }}
          >
            {renderUpload('detailFileList')}
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>}
        {modalTitle === '检测项目' && <Project id={currentId} />}
      </Modal>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={()=>this.setState({previewVisible:false}) }
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  }
}
