import React, { useEffect } from 'react'
import './index.scss'
import { Menu, } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { links } from 'router/index'
import store from 'store'


export default function Sidebar(props) {

  // 监听浏览器前进/后退
  useLocation()
  const history = useHistory()

  const currentUrl = window.location.pathname
  // 提供给顶部栏标题显示
  useEffect(() => {
    const param = links.find(i => i.url === currentUrl).title
    store.dispatch({ type: 'location', param })
  }, [currentUrl])

  const renderItem = (url, title, icon) => {
    return <Menu.Item
      key={url}
      className='sidebar-item'
      onClick={() => history.push(url)}
    >
      {icon}
      <span>
        {title}
      </span>
    </Menu.Item>
  }
  const menuItem = links.map(item => {
    const { hidden, meta, url, title } = item
    if (hidden)
      return false
    const icon = meta && meta.icon ? meta.icon : ''
    return renderItem(url, title, icon)
  })

  return (
    <div className='sidebarWrapper'>
      <div className="sideBarMenu">
        <div className="logo">
          <img src={require('../../../assets/images/logo.svg')} alt="logo" />
        </div>
        <Menu
          selectedKeys={[currentUrl]}
          mode="inline"
          theme="dark"
        >
          {menuItem}
        </Menu>
      </div>
    </div>
  )
}
