/* eslint-disable */
import React from 'react'
import { Popover, Row, Col, Form, Input, Button, Select, InputNumber, message } from 'antd'
import request from 'utils/request'

import './orderDetail.scss'

const { Option } = Select
const dates = ['今天', '明天', '后天']
const times = [
  '08:00-09:00',
  '09:00-10:00',
  '10:00-11:00',
  '11:00-12:00',
  '12:00-13:00',
  '13:00-14:00',
  '14:00-15:00',
  '15:00-16:00',
  '16:00-17:00',
  '17:00-18:00',
  '18:00-19:00',
  '19:00-20:00',
  '20:00-21:00',
]
export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formRef: React.createRef(),
      company: '',
      companiesList: [],
      departmentsList: [],
      isDepartmentDisabled: true,
      selectedDate: '',
      selectedRange: '',
      popVisible: false,
      logisticsNumber: '',
      isShowLogisticsBtn: props.status === 20
    }
    this.mainUrl = this.props.url + this.props.id + '/'
  }

  componentDidMount() {
    request({ url: this.mainUrl, method: 'GET' }).then(({ data }) => {
      this.state.formRef.current.setFieldsValue(data)
      // const { expected_time, logistics_number } = data
      this.setState({
        logisticsNumber: logistics_number,
        // selectedDate: expected_time.slice(0, 2),
        // selectedRange: expected_time.slice(2, expected_time.length)
      })
    })
  }

  setLogisticsNumber = (e) => {
    this.setState({ logisticsNumber: e.target.value })
  }
  addLogisticsNumber = () => {
    request({
      url: this.mainUrl + 'action/',
      method: 'PUT',
      data: {
        logistics_number: this.state.logisticsNumber,
        status: this.props.status
      }
    }).then(({ data }) => {
      this.setState({ isShowLogisticsBtn: false })
      message.success(data)
      this.hidePop()
    })
  }

  hidePop = () => {
    this.setState({ popVisible: false })
  }
  popVisibleChange = (v) => {
    if (this.state.logisticsNumber)
      this.setState({ popVisible: v })
  }

  onFinish = (values) => {
    const { selectedDate, selectedRange } = this.state
    // values.expected_time = selectedDate + ' ' + selectedRange
    request({ url: this.mainUrl, method: 'PUT', data: values }).then(({ data }) => {
      message.success(data)
      this.props.onSubmit()
    })
  }

  renderRequiredFormItem(label, name) {
    return <Col span={8}>
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: true, message: '请输入' + label }]}
      >
        <Input />
      </Form.Item>
    </Col>
  }
  renderInlineFormItem(label, name, disabled) {
    return <Col span={8}>
      <Form.Item
        label={label}
        name={name}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </Col>
  }
  renderConfirmLogistics() {
    return <div>
      <div>确认发货？</div>
      <Button size='small' onClick={this.hidePop}>取消</Button>
      <Button size='small' type='primary' onClick={this.addLogisticsNumber}>确定</Button>
    </div>
  }
  renderPopBtn() {
    if (this.state.isShowLogisticsBtn)
      return <Popover
        content={this.renderConfirmLogistics()}
        trigger="click"
        visible={this.state.popVisible}
        onVisibleChange={this.popVisibleChange}
      >
        <div className='send-logistics-btn'>发货</div>
      </Popover>
  }

  render() {
    const { selectedDate, selectedRange } = this.state
    const { renderInlineFormItem, renderRequiredFormItem } = this
    const { status } = this.props
    const phonePattern = /^[1]([3-9])[0-9]{9}$/
    const phoneErrorMessage = '请输入正确格式的手机号码'

    return <Form
      className='order-detail'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      ref={this.state.formRef}
      onFinish={this.onFinish}
    >

      <h3>订单信息</h3>
      <Row>
        {renderInlineFormItem('订单编号', 'number', true)}
        {renderInlineFormItem('检测套餐', 'name', true)}
        {renderInlineFormItem('价格', 'price', true)}
      </Row>
      <Row>
        {renderInlineFormItem('下单时间', 'create_time', true)}
        {renderInlineFormItem('支付时间', 'pay_time', true)}
        {/* {renderInlineFormItem('条码号', 'serial_number',)} */}
      </Row>

      <h3>收货信息</h3>
      <Row>
        {this.renderRequiredFormItem('收货人', 'receiver')}
        <Col span={8}>
          <Form.Item
            label='收货号码'
            name='receiver_phone_number'
            rules={[{
              pattern: phonePattern,
              message: phoneErrorMessage
            }, {
              required: true,
              message: '请输入收货号码'
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label='物流编号'
            name='logistics_number'
          >
            <Input onChange={this.setLogisticsNumber} addonAfter={this.renderPopBtn()} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label='收货地址'
        name='receiver_address'
        rules={[{ required: true, message: '请输入收货地址' }]}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 22 }}
      >
        <Input />
      </Form.Item>

      {/* <h3>检测信息</h3>
      <Row>
        {renderRequiredFormItem('检测人', 'nickname',)}
        {renderRequiredFormItem('性别', 'gender',)}
        <Col span={8}>
          <Form.Item
            label='年龄'
            name='age'
            rules={[{ required: true, message: '请输入年龄' }]}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row> */}

      <h3>备注信息</h3>
      <Form.Item
        label='备注客户'
        name='remark'
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 22 }}
      >
        <Input />
      </Form.Item>

      {/* {[50, 60, 80, 90].includes(status) && <div>
        <h3>寄件信息</h3>
        <Row>
          {renderInlineFormItem('寄件人', 'sender',)}
          <Col span={8}>
            <Form.Item
              label='寄件号码'
              name='sender_phone_number'
              rules={[{
                pattern: phonePattern,
                message: phoneErrorMessage
              }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label='寄件时间'
              name='expected_time'
            >
              <div className='expected-time'>
                <Select value={selectedDate} onChange={v => this.setState({ selectedDate: v })}>
                  {dates.map(i => <Option key={i} value={i}>{i}</Option>)}
                </Select>
                <Select value={selectedRange} onChange={v => this.setState({ selectedRange: v })}>
                  {times.map(i => <Option key={i} value={i}>{i}</Option>)}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {renderInlineFormItem('预约时间', 'appointment_time', true)}
        </Row>
        <Form.Item
          label='寄件地址'
          name='sender_address'
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
        >
          <Input />
        </Form.Item>
      </div>} */}

      {[100, 105, 110].includes(status) && <div>
        <h3>退款信息</h3>
        <Form.Item
          label='客户退款理由'
          name='refund_reason'
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label='退款备注'
          name='record'
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
        >
          <Input />
        </Form.Item>
      </div>
      }

      {[80, 90].includes(status) && <div>
        <h3>入库信息</h3>
        <Form.Item
          label='入库备注'
          name='record'
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
        >
          <Input />
        </Form.Item>
      </div>}

      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button type='primary' htmlType="submit">
          保存
        </Button>
      </Form.Item>
    </Form >
  }
}
