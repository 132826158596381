import request from 'utils/request'

export function fetchSamplesRenderingFieldsList(query) {
  return request({
    url: 'samples/rendering_fields/',
    method: 'get',
    params: query
  })
}
export function fetchSamplesValidFieldsList(query) {
  return request({
    url: 'samples/valid_fields/',
    method: 'get',
    params: query
  })
}
export function fetchSamplesList(query) {
  return request({
    url: 'samples/',
    method: 'get',
    params: query
  })
}
export function fetchSampleDetail(id) {
  return request({
    url: `samples/${id}/`,
    method: 'get'
  })
}
// 检测入库
export function putInStorage(id, status) {
  return request({
    url: `samples/${id}/action/`,
    method: 'PUT',
    data: { status }
  })
}
export function addSample(data) {
  return request({
    url: `samples/`,
    method: 'post',
    data
  })
}
export function modifySample(id, data) {
  return request({
    url: `samples/${id}/`,
    method: 'PUT',
    data
  })
}
export function changeSampleResult(id, data) {
  return request({
    url: `samples/${id}/`,
    method: 'PATCH',
    data
  })
}
export function deleteSample(id) {
  return request({
    url: `samples/${id}/`,
    method: 'delete'
  })
}
export function recoverSample(data) {
  return request({
    url: `samples/recover/`,
    method: 'post',
    data
  })
}
export function importSamples(data) {
  return request({
    url: `samples/upload/`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}
export function exportSamples(data) {
  return request({
    url: `samples/export_samples/`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}
export function fetchSamplesValidSerialNumber(query) {
  return request({
    url: `samples/valid_serial_number/`,
    method: 'get',
    params: query,
  })
}
export function verifySample(id, data) {
  return request({
    url: `samples/${id}/verify/`,
    method: 'PUT',
    data,
  })
}
export function fetchUploadedStatusObj(data, url = 'samples') {
  return request({
    url: `${url}/file_part_pre_upload/`,
    method: 'post',
    data,
  })
}
export function sampleFilesUpload(data, cancelToken, url = 'samples') {
  return request({
    url: `${url}/file_part_upload/`,
    method: 'post',
    data,
    cancelToken
  })
}
export function downloadSampleTemplates() {
  return request({
    url: `samples/upload/`,
    method: 'get',
    // responseType: 'blob'
  })
}
export function bulkDeleteSamples(data) {
  return request({
    url: `samples/bulk_delete/`,
    method: 'POST',
    data
  })
}

export function bulkResetSamples(data) {
  return request({
    url: `samples/bulk_reset/`,
    method: 'POST',
    data
  })
}

export function fetchHospitalsList(query) {
  return request({
    url: 'submitted_hospitals/',
    method: 'get',
    params: query
  })
}
export function fetchHospitalsDetail(id) {
  return request({
    url: `submitted_hospitals/${id}/`,
    method: 'get'
  })
}
export function fetchDepartmentsList(query) {
  return request({
    url: 'submitted_departments/',
    method: 'get',
    params: query
  })
}

export function fetchDoctorsList(query) {
  return request({
    url: 'submitted_doctors/',
    method: 'get',
    params: query
  })
}
export function fetchSamplesSavedValuesList(query) {
  return request({
    url: 'samples/saved_values/',
    method: 'get',
    params: query
  })
}

export function fetchDepartmentsListAtCertainId(id) {
  return request({
    url: `submitted_hospitals/${id}/`,
    method: 'get'
  })
}

export function fetchDoctorsListAtCertainId(id) {
  return request({
    url: `submitted_departments/${id}/`,
    method: 'get'
  })
}

export function fetchLibrariesBox(query) {
  return request({
    url: 'library_boxes/',
    method: 'get',
    params: query
  })
}

export function fetchI5List(query) {
  return request({
    url: 'i5_indexes/',
    method: 'get',
    params: query
  })
}

export function fetchI7List(query) {
  return request({
    url: 'i7_indexes/',
    method: 'get',
    params: query
  })
}

export function fetchSamplesTypesList(query) {
  return request({
    url: 'sample_types/',
    method: 'get',
    params: query
  })
}
export function fetchSampleTypesList(query) {
  return request({
    url: 'sample_types/',
    method: 'get',
    params: query
  })
}
/** **********************************文库***********************************************************/
export function fetchLibrariesList(query) {
  return request({
    url: `libraries/`,
    method: 'get',
    params: query
  })
}
export function fetchLibraryDetail(id) {
  return request({
    url: `libraries/${id}/`,
    method: 'get'
  })
}
export function fetchLibraryDetail2(query) {
  return request({
    url: `libraries/`,
    method: 'get',
    params: query
  })
}
export function modifyLibrary(id, data) {
  return request({
    url: `libraries/${id}/`,
    method: 'put',
    data
  })
}
export function deleteLibrary(id) {
  return request({
    url: `libraries/${id}/`,
    method: 'delete'
  })
}
export function addLibrary(data) {
  return request({
    url: `libraries/`,
    method: 'post',
    data
  })
}
export function bulkDeleteLibraries(data) {
  return request({
    url: `libraries/bulk_delete/`,
    method: 'post',
    data
  })
}
export function newBulkLibraries(data) {
  return request({
    url: `libraries/bulk_create/`,
    method: 'post',
    data
  })
}
export function fetchObsoletedLibrariesList(query) {
  return request({
    url: 'libraries/obsoleted_list/',
    method: 'get',
    params: query
  })
}
/* 获取文库类型*/
export function fetchLibraryTypesList(query) {
  return request({
    url: 'library_types/',
    method: 'get',
    params: query
  })
}
/* 获取建库试剂盒*/
export function fetchLibraryKitsList(query) {
  return request({
    url: 'library_kits/',
    method: 'get',
    params: query
  })
}
/* 获取建库人*/
export function fetchBuildersList(query) {
  return request({
    url: 'system_users/builders/',
    method: 'get',
    params: query
  })
}
/* 获取i7index*/
export function fetchI7IndexesList(query) {
  return request({
    url: 'i7indexes/',
    method: 'get',
    params: query
  })
}/* 获取i5index*/
export function fetchI5IndexesList(query) {
  return request({
    url: 'i5indexes/',
    method: 'get',
    params: query
  })
}
/* 单个处理*/
export function obsoleteLibrary(id, data) {
  return request({
    url: `libraries/${id}/obsolete/`,
    method: 'post',
    data
  })
}
export function recoverLibrary(id) {
  return request({
    url: `libraries/${id}/recover/`,
    method: 'post'
  })
}
/** ***************************************************文库********************************************/
export function fetchNucleicAcidsList(query) {
  return request({
    url: 'nucleic_acids/',
    method: 'get',
    params: query
  })
}
export function fetchObsoletedNucleicAcidsList(query) {
  return request({
    url: 'nucleic_acids/obsoleted_list/',
    method: 'get',
    params: query
  })
}
export function fetchNucleicAcidDetail(id) {
  return request({
    url: `nucleic_acids/${id}/`,
    method: 'get'
  })
}
export function modifyNucleicAcid(id, data) {
  return request({
    url: `nucleic_acids/${id}/`,
    method: 'put',
    data
  })
}

export function deleteNucleicAcid(id) {
  return request({
    url: `nucleic_acids/${id}/`,
    method: 'delete'
  })
}
export function newNucleicAcid(data) {
  return request({
    url: `nucleic_acids/`,
    method: 'post',
    data
  })
}
export function newBulkNucleicAcids(data) {
  return request({
    url: `nucleic_acids/bulk_create/`,
    method: 'post',
    data
  })
}
/** *********************************单个处理***************************************/
export function obsoleteNucleicAcid(id, data) {
  return request({
    url: `nucleic_acids/${id}/obsolete/`,
    method: 'post',
    data
  })
}
export function recoverNucleicAcid(id) {
  return request({
    url: `nucleic_acids/${id}/recover/`,
    method: 'post'
  })
}
/** *********************************批量处理***************************************/
export function bulkObsoleteNucleicAcid(data) {
  return request({
    url: `nucleic_acids/bulk_obsolete/`,
    method: 'post',
    data
  })
}
export function bulkRecoverNucleicAcid(data) {
  return request({
    url: `nucleic_acids/bulk_recover/`,
    method: 'post',
    data
  })
}
/* 获取提取试剂盒*/
export function fetchNucleicAcidKitsList(query) {
  return request({
    url: 'nucleic_acid_kits/',
    method: 'get',
    params: query
  })
}
/* 获取核酸提取人*/
export function fetchNucleicAcidExtractorsList(query) {
  return request({
    url: 'system_users/extractors/',
    method: 'get',
    params: query
  })
}
// 批量获取核酸信息
export function fetchBulkNucleicAcidsList(query) {
  return request({
    url: 'nucleic_acids/bulk_update/',
    method: 'get',
    params: query
  })
}
export function modifyBulkNucleicAcid(data) {
  return request({
    url: `nucleic_acids/bulk_update/`,
    method: 'put',
    data
  })
}

export function fetchAnalysisModuleList(query) {
  return request({
    url: 'analysis/get_modules/',
    method: 'get',
    params: query
  })
}
export function fetchAnalysisStatusList(query) {
  return request({
    url: 'analysis/all_status/',
    method: 'get',
    params: query
  })
}
export function fetchSampleStatuses(params) {
  return request({
    url: 'config/',
    method: 'GET',
    params
  })
}