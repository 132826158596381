/* eslint-disable */
import React from 'react'
import { Form, Input, Button, Select } from 'antd'
import { validateMessages, tailLayout, layout } from 'utils/index'
import { validPhone } from 'utils/validate'
import { requiredRule } from 'utils'

const { Option } = Select

export default class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formRef: React.createRef(),
      company: '',
      companiesList: [],
      departmentsList: [],
      isDepartmentDisabled: true,

      avatarUrl: ''
    }
  }

  componentDidMount() {
    const { form } = this.props
    this.state.formRef.current.setFieldsValue(this.props.form)
    if (form.avatar) {
      this.setState({ avatarUrl: form.avatar })
    }
  }

  handleChange = (value) => {
    this.setState({
      company: value,
      isDepartmentDisabled: false
    }, () => {
      this.getDepartmentsList()
      this.state.formRef.current.setFieldsValue({
        department: [],
      })
    })
  }

  onFinish = (values) => {
    this.props.submitForm(values)
  };
  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  };

  render() {
    return <Form
      className='user-form'
      {...layout}
      ref={this.state.formRef}
      name="basic"
      initialValues={{ is_closed: true }}
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
      validateMessages={validateMessages}
    >
      <Form.Item
        label="账号"
        name='account'
        rules={requiredRule()}
      >
        <Input disabled={this.props.name === 'detail'} />
      </Form.Item>
      <Form.Item
        label="用户名"
        name='nickname'
        rules={requiredRule()}
      >
        <Input />
      </Form.Item>
      {this.props.name === 'add' &&
        <Form.Item
          label="密码"
          name='password'
          rules={requiredRule()}
        >
          <Input.Password />
        </Form.Item>}
      <Form.Item label="备注" name='remark'>
        <Input />
      </Form.Item>
      <Form.Item {...tailLayout}>
        {this.props.name === 'add' ?
          <Button type="primary" htmlType="submit" className={'mR15'}>
            创建
          </Button> :
          <Button className={'mR15'} type='primary' htmlType="submit">
            确定
          </Button>}
        <Button onClick={this.props.cancelForm}>
          取消
        </Button>
      </Form.Item>
    </Form>

  }
}
