import User from 'views/User'
import Plan from 'views/Plan'
import Detection from 'views/Detection'
import Order from 'views/Order'
import Login from 'views/Login/index'
import Sample from '../views/Sample/index'
import ErrorPage from '../views/ErrorPage/index'

const links = [
  {
    url: '/login',
    title: '登录',
    hidden: true,
  },
  {
    url: '/404',
    title: '错误页面',
    hidden: true,
  },
  {
    url: '/',
    title: '订单管理',
  },
  {
    url: '/detection',
    title: '检测管理',
  },
  {
    url: '/plans',
    title: '套餐管理',
  },
  {
    url: '/sample',
    title: '样本管理',
  },
  {
    url: '/users',
    title: '用户管理',
  },
]

const routeComponents = [
  { path: '/login', component: Login },
  { path: '/404', component: ErrorPage },
  { path: '/plans', component: Plan },
  { path: '/detection', component: Detection },
  { path: '/sample', component: Sample },
  { path: '/users', component: User },
  // path 为 / 的组件要放在最后才起作用
  { path: '/', component: Order },
]


export { links, routeComponents }
