import React from 'react'
import { Button, Input, Form, message } from 'antd'

import request from 'utils/request'

export default function (props) {
  const { url, refundReason, status } = props

  const onFinish = (values) => {
    request({
      url,
      method: 'PUT',
      data: {
        record: values.record,
        status,
        refund_reason: refundReason
      }
    }).then(({ data }) => {
      message.success(data)
      props.onSubmit()
    })
  }



  return <Form
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 18 }}
    onFinish={onFinish}
  >
    <Form.Item
      label='客户退款理由'
      name='refundReason'
      initialValue={refundReason}
    >
      <Input disabled />
    </Form.Item>
    <Form.Item label='备注' name='record'>
      <Input />
    </Form.Item>
    <Form.Item wrapperCol={{ offset: 4 }}>
      <Button type='primary' htmlType="submit">
        同意退款
      </Button>
    </Form.Item>
  </Form>
}