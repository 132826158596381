import React from 'react'
import { message, Select, Modal } from "antd"
import axios from './utils/request'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { Option } = Select
const { confirm } = Modal

export function rowSelection(selectedRowKeys, obj) {
  const localSelected = [...selectedRowKeys]
  return {
    selectedRowKeys,
    onSelect(record, selected) {
      const { id } = record
      if (selected) {
        localSelected.push(id)
      } else {
        const index = localSelected.indexOf(id)
        localSelected.splice(index, 1)
      }
      console.log(localSelected)
      obj.setState({ selectedRowKeys: localSelected })
    },
    onSelectAll(selected, selectedRows, changeRows) {
      const selectAll = changeRows.map(item => item.id)
      if (selected) {
        obj.setState({ selectedRowKeys: localSelected.concat(selectAll) })
      } else {
        obj.setState({ selectedRowKeys: localSelected.filter(a => !selectAll.includes(a)) })
      }
    }
  }
}

// 勾选表格项避免分页后上一页的勾选项丢失
export function keepDifferentRow(record, selected, selectedRowKeys) {

}

// 编辑各类详情表单时，判断选择框的值是刚打开时的字符串，还是选择后的 id
export function checkSelectValue(value, arr) {
  if (Number.isNaN(parseInt(value)))
    return (arr.find(item => item.name === value)).id
  return value
}

export function numberParser(value, unit) {
  if (unit) {
    value = value.replace(unit, '')
  }
  const lastIndex = value.length - 1
  const isNumber = !Number.isNaN(parseInt(value.slice(lastIndex)))
  if (isNumber) {
    return Math.floor(value)
  }
  return value.slice(0, lastIndex)
}

export function base64UrlToBlob(dataUrl, filename = 'avatar') {
  let arr = dataUrl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let suffix = mime.split('/')[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  })
}

// 删除单个表格项
export function confirmSingleDelete(obj, url, id, title = '确认删除?') {
  return confirm({
    icon: <ExclamationCircleOutlined />,
    title,
    onOk() {
      (() => {
        obj.setState({
          selectedRowKeys: [],
          currentPage: 1
        })
        axios.delete(url + id + '/').then(({ data }) => {
          // handleResponse(data, obj.getList)
          message.success('删除成功！')
          obj.getList()
        }
        )
      })()
    }
  })
}

export function confirmMessage(func, title = '是否删除?') {
  return confirm({
    icon: <ExclamationCircleOutlined />,
    title,
    onOk() {
      func()
    }
  })
}

export function handleResponse(resData, callback) {
  const { success, messages } = resData
  if (success) {
    message.success(messages)
    callback && callback()
    return
  }
  message.error(messages)
}

export function getOptions(obj, url, stateName) {
  axios.get(url, {
    params: { not_page: true }
  }).then(({ data }) => {
    obj.setState({ [stateName]: data.results })
  })
}
export function optionsTransform(arr, key = 'name', valName = 'id') {
  return arr.map(item => <Option key={item[valName] || item}>{item[key] || item}</Option>)
}

export function noUndefined(object) {
  for (const item in object) {
    if (object[item] === undefined || object[item] === null || object[item] === 'undefined' || object[item] === 'null') {
      object[item] = ''
    }
  }
}

// 转换 select 的值
export function tranSelectValue(value, arr) {
  const isNan = Number.isNaN(parseInt(value))
  if (isNan) {
    const target = arr.find(item => item.name === value)
    return target.id
  }
  return value
}

// 切换回表格页后是否更新
export function updateTable(key, object) {
  if (key === '1') {
    object.setState({ isTab1: true })
    return
  }
  object.setState({ isTab1: false })
}

// 关闭查看文件的弹窗
export function onCloseModal(len, getData, setVisible) {
  if (len === 1 || !len)
    getData()
  setVisible(false)
}

// 表格排序
export function handleSort(sorter, key, setOrdering) {
  const { order } = sorter
  if (order === 'ascend') {
    setOrdering(key)
  }
  else if (order === 'descend') {
    setOrdering('-' + key)
  }
  else {
    setOrdering(undefined)
  }
}
export function handleSortInClass(obj, sorter) {
  const { order, field } = sorter
  obj.setState({
    sortOrdering: order === 'descend' ? '-' : '',
    sortProp: field
  },
    () => obj.getList()
  )
}

// 删除表格项
export function deleteProject(setId, id, mainUrl, setSpin, getData) {
  setId([]) // 清空这次的选项
  const length = id && id.length
  if (!length) {
    message.warning('请先选择项目！')
    return
  }
  let url = mainUrl + '/' + id[0] + '/'
  let submitData = {}

  if (length > 1) {
    url = mainUrl + '/bulk_delete/'
    submitData = {
      data: {
        deleted_objects: id
      }
    }
  }
  const deleteFn = () => {
    setSpin(true)
    axios.delete(
      url,
      submitData
    ).then(({ data }) => {
      handleResponse(data, getData)
    }).catch(() => {
      setSpin(false)
    })
  }
  confirmMessage(deleteFn)
}

// 必填规则
export function requiredRule() {
  return [{
    required: true,
    whitespace: true,
  }]
}
