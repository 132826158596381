import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Checkbox } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { baseUrl, errorSubmit } from "utils/index"
import { requestTimeout } from "utils/constant"
import store from 'store'
import './index.scss'
import {
  UserOutlined,
  LockOutlined,
} from '@ant-design/icons'
import FooterBottom from "../Layout/Footer"

const layout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 24,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 24,
  },
}

export default function Login() {
  let history = useHistory()
  let location = useLocation()
  const [form] = Form.useForm()
  const [isBtnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    const username = localStorage.getItem('username')
    if (username) {
      form.setFieldsValue({
        username: username,
        password: atob(localStorage.getItem('remember'))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = values => {
    let { from } = location.state || { from: { pathname: "/" } }

    const { remember, username, password } = values
    if (remember) {
      localStorage.setItem('username', username)
      localStorage.setItem('remember', btoa(password))
    } else {
      localStorage.removeItem('remember')
      localStorage.removeItem('username')
    }
    setBtnLoading(true)
    values.account = username
    axios({
      url: baseUrl.replace('api/', 'api/login/'),
      method: 'POST',
      data: values,
      timeout: requestTimeout
    }).then(res => {
      // localStorage.setItem('authInfo', JSON.stringify(data))
      // history.replace('/')
      localStorage.setItem('authInfo', JSON.stringify({ token: res.data }))
      let tempPromise = () => {
        return new Promise(resolve => {
          store.dispatch({ type: 'authenticate' })
          resolve()
        })
      }

      tempPromise().then(() => {
        setBtnLoading(false)
        history.replace(from)
      })
    }).catch(error => {
      message.error(error.response.data)
      setBtnLoading(false)
    })
  }
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
    const { errorFields } = errorInfo
    errorSubmit(errorFields)
  }

  return <div className='login-container'>
    <main className="main">
      <div className='system-name'>赛哲性病检测平台</div>
      <Form
        className='login-form'
        {...layout}
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="username"
          rules={[
            {
              required: true,
              message: '账号不能为空！',
            },
          ]}
        >
          <Input size="large" autoFocus prefix={<UserOutlined />} placeholder="账号" />
        </Form.Item>

        <Form.Item
          label=""
          name="password"
          rules={[
            {
              required: true,
              message: '密码不能为空！',
            },
          ]}
        >
          <Input.Password size="large" prefix={<LockOutlined />} placeholder="密码" />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox style={{ color: '#1890ff' }}>记住密码</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            loading={isBtnLoading}
            type="primary"
            htmlType="submit"
            size="large"
            className='width100'
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </main>
    <footer className="footer"><FooterBottom /></footer>
  </div>
}
