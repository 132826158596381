import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Input, Menu, Dropdown, Button, Modal, message } from 'antd'
import { DownOutlined, } from '@ant-design/icons'

import { userResetPassword, modifyUser, fetchUserDetail } from 'api/set/user'
import store from '../../../store'

import './index.scss'


export default function Header() {
  const [name, setName] = useState('')
  const [userForm, setUserForm] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  // 页面标题
  const [contentTitle, setContentTitle] = useState('')
  store.subscribe(() => {
    setContentTitle(store.getState().currentLocation.param)
  })


  const authInfo = JSON.parse(localStorage.getItem('authInfo')).token
  let history = useHistory()
  let location = useLocation()

  const handleSubmit = (v) => {
    const { id } = authInfo
    let fn = () => {
      return modifyUser(id, v)
    }
    if (modalTitle === '修改密码') {
      fn = () => {
        return userResetPassword(id, v)
      }
    }
    fn().then(({ data }) => {
      message.success(data)
      setModalVisible(false)
      if (modalTitle === '修改密码') {
        localStorage.clear()
        window.location.replace(window.location.origin + '/login')
      }
    })
  }

  useEffect(() => {
    if (authInfo) {
      setName(authInfo.account)
    }
  }, [authInfo])

  const menuClick = (value) => {
    const { key } = value
    switch (key) {
      case 'account':
        fetchUserDetail(authInfo.id).then(({ data }) => {
          setUserForm(data)
          setModalVisible(true)
          setModalTitle('账号信息')
        })
        break
      case 'logout':
        localStorage.removeItem('authInfo')
        history.push("/login", { from: location })
        break
      case 'password':
        setModalVisible(true)
        setModalTitle('修改密码')
        break
      default:
    }
  }

  const menu = (
    <Menu onClick={menuClick}>
      <Menu.Item key="account">
        账号信息
      </Menu.Item>
      <Menu.Item key="password">
        修改密码
      </Menu.Item>
      <Menu.Item key="logout">
        退出登录
      </Menu.Item>
    </Menu>)

  return <header className='header'>
    <div className='headerMainWrapper flexWrap alignItems spaceBetween'>
      <div className='content-title'>
        {contentTitle}
      </div>

      <div className='flexWrap alignItems'>
        <Dropdown overlay={menu} trigger={['click']} className='mL15'>
          <span className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {name}
            {' '}
            <DownOutlined />
          </span>
        </Dropdown>
      </div>
    </div>
    {/* 标签栏
      <div className='tagsWrapper flexWrap alignItems'>
        <BottomTabs />
      </div> */}
    <Modal
      title={modalTitle}
      visible={modalVisible}
      footer={null}
      onCancel={() => setModalVisible(false)}
      destroyOnClose
    >
      {modalTitle === '账号信息' && <Form
        labelCol={{ span: 4 }}
        initialValues={userForm}
        onFinish={handleSubmit}
      >
        <Form.Item label='用户账号' name='account'>
          <Input disabled />
        </Form.Item>
        <Form.Item label='用户名' name='nickname'><Input /></Form.Item>
        <Form.Item label='备注' name='remark'><Input /></Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>}
      {modalTitle === '修改密码' && <Form
        labelCol={{ span: 4 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label='原密码'
          name='old_password'
          rules={[{ required: true }]}
        >
          <Input type='password' />
        </Form.Item>
        <Form.Item
          label='新密码'
          name='new_password'
          rules={[{ required: true }]}
        >
          <Input type='password' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>}
    </Modal>
  </header>
}
