import React, { useEffect, useState } from 'react'
import { Table } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'

import {
  fetchLogsList,
} from 'api/logs/logs'
import {
  fetchSamplesRenderingFieldsList,
  fetchSamplesValidSerialNumber,
} from 'api/sample'

import { objectFromEntriesAlternative } from 'utils/index'

export default function (props) {
  const [currentTableName] = useState('samples')

  const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false)

  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')
  const [sortOrdering] = useState('')
  const [sortProp] = useState('')

  useEffect(() => {
    // if (props.name === 'sample') {
    //   getRenderingFieldsList()
    //   getSamplesValidSerialNumber()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchTxt, sortOrdering, sortProp])

  const listQuery = () => {
    let tempObj = {
      object_type: props.type,
      object_id: `${props.objectId}`,
      page: `${currentPage}`,
      page_size: `${pageSize}`,
      search: `${searchTxt}`,
    }
    return tempObj
  }

  // 渲染列表
  const getRenderingFieldsList = async () => {
    await fetchSamplesRenderingFieldsList().then(response => {
      let tempObj = { ...response.data.results }
      let entries = Object.entries(tempObj).filter(a => {
        return a[0].includes('backup_field_')
      })
      let totalEntriesLen = entries.length
      let backupFields = []
      let datetimeArr = []
      for (let i = 0, len = totalEntriesLen / 4; i < len; i++) {
        let tempArr = []
        let num = (i + 1).toString()
        for (let j = 0; j < totalEntriesLen; j++) {
          let key = entries[j][0]
          let keySplitArr = key.split('_')
          if (keySplitArr.includes(num)) {
            // console.log(entries[j])
            tempArr.push(entries[j])
            if (keySplitArr.includes('type') && entries[j][1] === 'datetime') {
              datetimeArr.push(key.replace('_type', ''))
            }
          }
        }
        backupFields.push(objectFromEntriesAlternative(tempArr))
      }
    })
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    await fetchLogsList(listQuery()).then(({ data }) => {
      setIsIsTableLoading(false)
      setTable(data.results)
      setAllCount(data.count)

      if (isSearchBtnLoading) {
        setIsSearchBtnLoading(false)
      }
    })
  }
  const getSamplesValidSerialNumber = () => {
    fetchSamplesValidSerialNumber().then(response => {
      // setSamplesValidSerialNumber(response.data.results)
    })
  }

  const onSearchChange = (value) => {
    setIsSearchBtnLoading(true)
    if (value === searchTxt) {
      setCurrentPage(1)
      getList().then(() => {
        setIsSearchBtnLoading(false)
      })
    } else {
      setCurrentPage(1)
      setSearchTxt(value)
    }
  }
  const onShowSizeChange = (name, current, pageSize) => {
    console.log(name, current, pageSize)
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page, pageSize) => {
    console.log(name, page, pageSize)
    setCurrentPage(page)
  }


  let currentColumns = [
    { dataIndex: 'creator', title: '操作用户' },
    { dataIndex: 'place', title: 'IP地址' },
    { dataIndex: 'create_time', title: '操作时间' },
    { dataIndex: 'description', title: '具体操作' },
  ]

  return <div className='mainWrapper'>
    <SearchWithWrapper loading={isSearchBtnLoading} value={searchTxt} onChange={onSearchChange} />

    <Table
      loading={isTableLoading}
      rowKey='create_time'
      dataSource={props.name !== 'dynamicField' ? table : props.data}
      columns={currentColumns}
      pagination={false}
    />
    {props.name !== 'dynamicField' &&
      <EvenPagination current={currentPage}
        total={allCount}
        disabled={isTableLoading}
        onShowSizeChange={onShowSizeChange}
        onPageChange={onPageChange}
        name={currentTableName}>
      </EvenPagination>}
  </div>
}
