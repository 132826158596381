/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Row, Col, Menu, Dropdown, Table, Modal } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'
import EvenModal from 'components/lg/modal'
import LogTable from 'components/lg/log/index.js'
import VerifySample from './components/verifySample'

import {
  fetchSamplesList,
  fetchSampleDetail,
} from 'api/sample'

import './index.scss'

const { Item } = Menu

export default function SamplePage(props) {

  const [currentTableName] = useState('samples')

  const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false)

  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)
  const [currentId, setCurrentId] = useState(null)
  const [searchTxt, setSearchTxt] = useState('')
  // const [sortOrdering, setSortOrdering] = useState('')
  // const [sortProp, setSortProp] = useState('')
  const [selectedStatuses, setSelectedStatuses] = useState('20,25,30')
  const [searchForm, setSearchForm] = useState({})

  const [modalVisible, setModalVisible] = useState(false)

  const [evenModalVisible, setEvenModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState({
    modalContentItem_addSample: false,
    modalContentItem_sampleDetail: false,
    modalContentItem_search: false,
    modalContentItem_bulkAdd: false,
    modalContentItem_log: false,
    modalContentItem_resetPassword: false
  })
  const [modalClosable, setModalClosable] = useState(true)
  const [modalMaskClosable, setModalMaskClosable] = useState(true)
  const [modalMaskShow, setModalMaskShow] = useState(true)
  const [modalStyle] = useState({})
  const [modalZIndex, setModalZIndex] = useState(1000)

  // selected by default
  let samplesSelectedKeys = []
  let samplesSelectedRows = []
  const [selectedRowKeys, setSelectedRowKeys] = useState(samplesSelectedKeys)
  const [selectedRows, setSelectedRows] = useState(samplesSelectedRows)

  const [sampleDetail, setSampleDetail] = useState({})


  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchTxt, selectedStatuses])


  const listQuery = () => {
    let tempObj = {
      page: `${currentPage}`,
      page_size: `${pageSize}`,
      search: `${searchTxt}`,
      status: selectedStatuses
    }
    if (searchForm === {}) {
      return tempObj
    } else {
      return { ...tempObj, ...searchForm }
    }
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    const whichStatus = status => {
      switch (status) {
        case 20:
          return '待审核'
        case 25:
          return '已审核'
        case 30:
          return '已完成'
        default:
      }
    }
    fetchSamplesList(listQuery()).then(({ data }) => {
      const { results } = data
      for (const iterator of results) {
        iterator.statusTxt = whichStatus(iterator.status)
      }
      setTable(data.results)
      setAllCount(data.count)
      setIsIsTableLoading(false)

      if (isSearchBtnLoading)
        setIsSearchBtnLoading(false)
    })
  }


  const getDetail = (id, which) => {
    initModal()
    setCurrentId(id)
    setModalTitle(which)
    fetchSampleDetail(id).then(({ data }) => setSampleDetail(data))
    setModalVisible(true)
  }

  const onSearchChange = (value) => {
    setIsSearchBtnLoading(true)
    if (value === searchTxt) {
      setCurrentPage(1)
      getList().then(() => {
        setIsSearchBtnLoading(false)
      })
    } else {
      setCurrentPage(1)
      setSearchTxt(value)
    }
  }
  const onShowSizeChange = (name, current, pageSize) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page, pageSize) => {
    setCurrentPage(page)
  }
  const initModal = () => {
    setModalClosable(true)
    setModalMaskClosable(true)
    setModalMaskShow(true)
    setModalZIndex(1000)
  }
  const cancelModal = () => {
    setModalVisible(false)
    setEvenModalVisible(false)
  }
  const submitModal = () => {
    setModalVisible(false)
    getList()
  }


  const onTableSelect = (record, selected) => {
    let id = record.id
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, id]
      tempRowsArr = [...selectedRows, record]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => a !== id)
      tempRowsArr = selectedRows.filter(a => a.id !== id)
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)

  }
  const onTableSelectAll = (selected, selectedRowsWithBug, changeRows) => {
    let idArr = changeRows.map(a => a.id)
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, ...idArr]
      tempRowsArr = [...selectedRows, ...changeRows]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => !idArr.includes(a))
      tempRowsArr = selectedRows.filter(a => !idArr.includes(a.id))
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)
  }
  const handleTableChange = (pagination, filters, sorter, tableName) => {
    const { statusTxt } = filters
    let selectedStatuses = '20,25,30'
    if (statusTxt)
      selectedStatuses = filters.statusTxt.join()
    setSelectedStatuses(selectedStatuses)
  }
  // 排序
  // const sortChange = (val, tableName) => {
  //   switch (tableName) {
  //     case 'samples':
  //       switch (val.order) {
  //         case 'ascend':
  //           // 升序
  //           setSortOrdering('')
  //           break
  //         case 'descend':
  //           // 降序
  //           setSortOrdering('-')
  //           break
  //         case undefined:
  //           // 取消
  //           setSortOrdering('')
  //           break
  //         default:
  //       }
  //       if (val.order === undefined) {
  //         setSortProp('')
  //       } else {
  //         setSortProp(val.field)
  //         if (/.name$/.test(val.field)) {
  //           let tempProp = sortProp.replace(/.name$/, '')
  //           setSortProp(tempProp)
  //         }
  //       }
  //       break
  //     default:
  //   }
  // }
  const openLog = id => {
    setEvenModalVisible(true)
    setCurrentId(id)
    setModalContent({
      modalContentItem_log: true
    })
  }
  const rowClassName = (record, index) => {
    if (record.is_deleted) {
      return 'highlight-info'
    } else {
      return ''
    }
  }

  const openReport = id => {
    fetchSampleDetail(id).then(({ data }) => {
      window.open(data.report_url)
    })
  }

  const menu = (id, status) => <Menu>
    {status === 30 && <Item key={4} onClick={() => openReport(id)}>查看报告</Item>}
    <Item key={5} onClick={() => openLog(id)}>操作日志</Item>
  </Menu>

  let filters = [
    { text: '待审核', value: 20 },
    { text: '已审核', value: 25 },
    { text: '已完成', value: 30 },
  ]
  let columns = [
    // { title: '订单编号', dataIndex: 'number' },
    { title: '条码号', dataIndex: 'serial_number' },
    { title: '检测套餐', dataIndex: 'name' },
    { title: '受检者', dataIndex: 'nickname' },
    { title: '性别', dataIndex: 'gender' },
    { title: '年龄', dataIndex: 'age' },
    { title: '客户电话', dataIndex: 'client_phone_number' },
    {
      title: '状态',
      dataIndex: 'statusTxt',
      filters
    },
    // { title: '检测结果', dataIndex: 'result' },
    {
      title: '操作',
      dataIndex: 'id',
      render: (text, record) => (
        <div className='operation-column'>
          {/* <a onClick={() => getDetail(text, '样本详情')}>详情</a> */}
          <a onClick={() => getDetail(text, '审核')}>审核</a>
          <Dropdown overlay={menu(text, record.status)}>
            <span className="ant-dropdown-link">
              更多
            </span>
          </Dropdown>
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    /*preserveSelectedRowKeys: true,*/
    onSelect: onTableSelect,
    onSelectAll: onTableSelectAll,
  }

  return <div className='mainWrapper sample-page'>
    <SearchWithWrapper
      loading={isSearchBtnLoading}
      value={searchTxt}
      onChange={onSearchChange}
    />

    <Table
      loading={isTableLoading}
      rowKey='id'
      dataSource={table}
      columns={columns}
      pagination={false}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      onChange={(pagination, filters, sorter) =>
        handleTableChange(pagination, filters, sorter, 'samples')}
      scroll={{ x: 1300 }}
    />
    <EvenPagination current={currentPage}
      total={allCount}
      disabled={isTableLoading}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}
      name={currentTableName}>
    </EvenPagination>


    <Modal
      width='50%'
      title={modalTitle}
      visible={modalVisible}
      closable={modalClosable}
      zIndex={modalZIndex}
      maskClosable={modalMaskClosable}
      mask={modalMaskShow}
      style={modalStyle}
      onCancel={cancelModal}
      footer={null}
      className='sample-modal'
    >
      {modalTitle === '样本详情' && <div className='detail'>
        <Row>
          <Col span={4}>订单编号</Col>
          <Col span={8}>{sampleDetail.number}</Col>
          <Col span={4}>检测套餐</Col>
          <Col span={8}>{sampleDetail.name}</Col>
        </Row>
        <Row>
          <Col span={4}>条码号</Col>
          <Col span={8}>{sampleDetail.serialNumber}</Col>
          <Col span={4}>检测人</Col>
          <Col span={8}>{sampleDetail.nickname}</Col>
        </Row>
        <Row className='last-row'>
          <Col span={4}>检测人性别</Col>
          <Col span={8}>{sampleDetail.gender}</Col>
          <Col span={4}>检测人年龄</Col>
          <Col span={8}>{sampleDetail.age}</Col>
        </Row>
      </div>}
      {modalTitle === '审核' && <div>
        <VerifySample
          sampleDetail={sampleDetail}
          submitModal={submitModal}
        />
      </div>}
    </Modal>

    <EvenModal
      title={modalTitle}
      visible={evenModalVisible}
      closable={modalClosable}
      zIndex={modalZIndex}
      maskClosable={modalMaskClosable}
      mask={modalMaskShow}
      style={modalStyle}
      cancel={cancelModal}
      submit={submitModal}>
      {modalContent.modalContentItem_log &&
        <LogTable
          type='samples'
          objectId={currentId}
        />}
    </EvenModal>
  </div>
}
