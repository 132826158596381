/* eslint-disable */
import React from 'react'
import { Dropdown, Menu, Table, message, Button } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'
import EvenModal from 'components/lg/modal'
import OrderDetail from './components/orderDetail'
import Logistics from './components/logistics'
import LogTable from 'components/lg/log/index.js'


import { noUndefined } from 'utils'
import request from 'utils/request'

import './index.scss'
import { confirmMessage } from 'utils'
import Refund from './components/refund'
import Storage from './components/storage'
import { exportOrders, fetchOrders } from 'api/order'
import fileDownload from 'js-file-download'

const mainUrl = 'orders/'
export default class Set extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTableName: 'users',
      isSearchBtnLoading: false,
      isTableLoading: false,
      table: [],
      currentPage: 1,
      pageSize: 10,
      allCount: 0,
      currentId: undefined,
      bulkDeleteArr: [],
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
      addForm: {},
      detailForm: {},
      resetPasswordForm: {},
      refundReason: '',

      modalVisible: false,
      modalTitle: '',

      selectedStatuses: '',
      selectedRowKeys: [],
      selectedRows: []
    }
  }

  componentDidMount() {
    this.getList()
  }

  deliverGood(record) {
    const { status, id, logistics_number } = record
    this.setState({
      modalTitle: '发货物流',
      modalVisible: true,
      logistics_number,
      currentId: id,
      status
    })
  }

  selectStatus(filters) {
    const { statusTxt } = filters
    let selectedStatuses = ''
    if (statusTxt) {
      selectedStatuses = filters.statusTxt.join()
    }
    this.setState({ selectedStatuses },
      () => {
        this.getList()
      })
  }

  getList = () => {
    const whichStatus = (code) => {
      switch (code) {
        case 10:
          return "待付款"
        case 15:
          return "付款中"
        case 20:
          return "待发货"
        case 30:
          return "已发货"
        // case 40:
        //   return "已绑定"
        // case 50:
        //   return "预约回寄"
        // case 60:
        //   return "回寄中"
        // case 80:
        //   return "检测中"
        // case 90:
        //   return "已完成"
        case 100:
          return "退款审核"
        case 105:
          return "退款中"
        case 110:
          return "已退款"
        case 120:
          return "取消订单"
      }
    }

    return new Promise((resolve) => {
      this.setState({
        isTableLoading: true
      })
      const params = {
        page: `${this.state.currentPage}`,
        page_size: `${this.state.pageSize}`,
        search: `${this.state.searchTxt}`,
        status: `${this.state.selectedStatuses}`,
      }
      fetchOrders(params).then(({ data }) => {
        let { results, count } = data
        for (const item of results) {
          noUndefined(item)
          item.statusTxt = whichStatus(item.status)
        }
        this.setState({
          isTableLoading: false,
          table: results,
          allCount: count,
        })
        resolve()
      })
    })
  }

  onSearchChange = (value) => {
    this.setState({
      isSearchBtnLoading: true,
      currentPage: 1,
      searchTxt: value,
    }, () => {
      this.getList().then(() => {
        this.setState({
          isSearchBtnLoading: false
        })
      })
    })
  }
  onShowSizeChange = (name, current, pageSize) => {
    this.setState({
      currentPage: 1,
      pageSize: pageSize
    }, () => {
      this.getList()
    })
  }
  onPageChange = (name, page, pageSize) => {
    this.setState({
      currentPage: page
    }, () => {
      this.getList()
    })
  }
  closeModalAndGetList = () => {
    this.setState({
      modalVisible: false
    })
    this.getList()
  }
  clickDropdownItem = (record, values) => {
    const { key } = values
    const { id, logistics_number, serial_number, status, refund_reason } = record
    let modalTitle = ''

    const fn = () => {
      request({
        url: mainUrl + id + '/action/',
        method: 'PUT',
        data: { status }
      }).then(({ data }) => {
        this.getList()
        message.success(data)
      })
    }
    switch (key) {
      case 'storage':
        modalTitle = '入库'
        break
      case 'refund':
        modalTitle = '退款审核'
        this.setState({ refundReason: refund_reason })
        break
      case 'bookSend':
        confirmMessage(fn, '确认将订单状态修改为回寄中？')
        return
      case 'log':
        modalTitle = '操作日志'
        break
    }
    this.setState({
      modalVisible: true,
      currentId: id,
      logistics_number,
      serial_number,
      status,
      modalTitle
    })
  }
  checkRow(id, status) {
    this.setState({
      currentId: id,
      modalVisible: true,
      status
    })
    this.setState({
      modalTitle: '订单详情'
    })
  }

  tabClick = (key, event) => {
    this.setState({
      currentTableName: key,
      currentPage: 1,
      pageSize: null,
      searchTxt: '',
      sortOrdering: '',
      sortProp: '',
    }, () => {
      this.getList()
    })
  }

  onTableSelect = (record, selected) => {
    let { id } = record
    let tempKeysArr, tempRowsArr
    const { selectedRows, selectedRowKeys } = this.state
    if (selected) {
      tempKeysArr = [...selectedRowKeys, id]
      tempRowsArr = [...selectedRows, record]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => a !== id)
      tempRowsArr = selectedRows.filter(a => a.id !== id)
    }
    this.setState({
      selectedRows: tempRowsArr,
      selectedRowKeys: tempKeysArr
    })
  }
  onTableSelectAll = (selected, selectedRowsWithBug, changeRows) => {
    let idArr = changeRows.map(a => a.id)
    let tempKeysArr, tempRowsArr
    const { selectedRows, selectedRowKeys } = this.state
    if (selected) {
      tempKeysArr = [...selectedRowKeys, ...idArr]
      tempRowsArr = [...selectedRows, ...changeRows]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => !idArr.includes(a))
      tempRowsArr = selectedRows.filter(a => !idArr.includes(a.id))
    }
    this.setState({
      selectedRows: tempRowsArr,
      selectedRowKeys: tempKeysArr
    })
  }

  exportOrdersList = () => {
    exportOrders(this.state.selectedRowKeys).then(({ data }) => {
      fileDownload(data, 'orders.xls')
    })
  }

  render() {
    const { currentId, modalTitle, status, refundReason, logistics_number, serial_number, table, selectedRowKeys } = this.state
    const { clickDropdownItem } = this
    const actionUrl = mainUrl + currentId + '/action/'

    // statuses: 使该选项显示的状态集合
    const renderMenuItem = (key, name, statuses, status) => {
      if (statuses.includes(status))
        return <Item key={key}>{name}</Item>
    }
    const { Item } = Menu
    const menu = (status, record) => <Menu
      onClick={clickDropdownItem.bind(this, record)}
    >
      {/* for test */}
      {renderMenuItem('refund', '退款审核', [100], status)}
      {/* {renderMenuItem('storage', '入库', [60], status)}
      {renderMenuItem('bookSend', '回寄', [50], status)} */}
      <Item key='log'>操作日志</Item>
    </Menu>

    const filters = [
      { text: '待付款', value: 10 },
      { text: '付款中', value: 15 },
      { text: '待发货', value: 20 },
      { text: '已发货', value: 30 },
      // { text: '已绑定', value: 40 },
      // { text: '预约回寄', value: 50 },
      // { text: '回寄中', value: 60 },
      // { text: '检测中', value: 80 },
      // { text: '已完成', value: 90 },
      { text: '退款审核', value: 100 },
      { text: '退款中', value: 105 },
      { text: '已退款', value: 110 },
      { text: '取消订单', value: 120 },
    ]
    const columns = [
      { title: '订单编号', dataIndex: 'number', },
      { title: '检测套餐', dataIndex: 'name', },
      { title: '下单用户', dataIndex: 'client', },
      { title: '收货人', dataIndex: 'receiver', },
      { title: '下单时间', dataIndex: 'create_time', },
      {
        title: '状态',
        dataIndex: 'statusTxt',
        filters
      },
      {
        title: '操作',
        dataIndex: 'id',
        render: (text, record) => (
          <div className='operation-column'>
            <a onClick={() => this.checkRow(text, record.status)}>详情</a>

            {[30, 40, 50, 60, 80, 90].includes(record.status) &&
              <a onClick={() => this.deliverGood(record)}>
                物流
              </a>}
            <Dropdown overlay={menu(record.status, record)}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                更多
              </a>
            </Dropdown>
          </div>
        ),
      },
    ]

    const rowSelection = {
      selectedRowKeys,
      onSelect: this.onTableSelect,
      onSelectAll: this.onTableSelectAll,
    }

    return <div className='mainWrapper'>
      <SearchWithWrapper
        loading={this.state.isSearchBtnLoading}
        value={this.state.searchTxt}
        onChange={this.onSearchChange}
      >
        <Button
          type='primary'
          disabled={!selectedRowKeys.length}
          onClick={this.exportOrdersList}
        >导出</Button>
      </SearchWithWrapper>
      <Table
        rowSelection={rowSelection}
        tableLayout='fixed'
        loading={this.state.isTableLoading}
        rowKey='id'
        dataSource={table}
        columns={columns}
        pagination={false}
        onChange={(pa, filters) => this.selectStatus(filters)}
      />
      <EvenPagination
        current={this.state.currentPage}
        total={this.state.allCount}
        onShowSizeChange={this.onShowSizeChange}
        onPageChange={this.onPageChange}
        name={this.state.currentTableName}
      />

      <EvenModal
        width='80%'
        title={modalTitle}
        visible={this.state.modalVisible}
        cancel={this.closeModalAndGetList}
        submit={() => this.setState({ modalVisible: false })}
      >
        {modalTitle === '订单详情' &&
          <OrderDetail
            url={mainUrl}
            id={currentId}
            status={status}
            onSubmit={this.closeModalAndGetList}
          />}
        {modalTitle === '发货物流' &&
          <Logistics
            url={actionUrl}
            status={status}
            id={currentId}
            logistics_number={logistics_number}
          />
        }
        {modalTitle === '入库' &&
          <Storage
            url={actionUrl}
            status={status}
            serial_number={serial_number}
            submit={this.closeModalAndGetList}
          />}
        {modalTitle === '操作日志' &&
          <LogTable
            name='log'
            type='orders'
            objectId={currentId}
          />}
        {modalTitle === '退款审核' &&
          <Refund
            status={status}
            url={actionUrl}
            refundReason={refundReason}
            onSubmit={this.closeModalAndGetList}
          />}
      </EvenModal>
    </div>
  }
}
