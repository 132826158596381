/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Steps } from 'antd'

import './logistics.scss'
import { fetchExpress } from 'api/order'

const { Step } = Steps

export default function (props) {
  const { logistics_number, id } = props

  const [logisticsNumber, setLogisticsNumber] = useState('')
  const [logisticsData, setLogisticsData] = useState([])

  const getExpressInfo = () =>
    fetchExpress(id).then(({ data }) => {
      setLogisticsData(data.data)
      setLogisticsNumber(data.nu)
    })

  useEffect(() => {
    if (logistics_number)
      getExpressInfo()
  }, [])


  return <div className='order-logistics'>
    <div className='header'>
      <div>
        <span>物流编号：{logistics_number || logisticsNumber}</span>
      </div>
    </div>
    <div>
      <Steps direction="vertical" current={0}>
        {logisticsData.map(i => <Step key={i.time} title={i.time} description={i.context} />)}
      </Steps>
    </div>
  </div>
}