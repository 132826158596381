import request from '../utils/request'

const mainUrl = 'clients/'

export function fetchClients(query) {
  return request({
    url: mainUrl,
    method: 'get',
    params: query,
  })
}

export function fetchClientDetail(id) {
  return request({
    url: `${mainUrl}${id}/`,
    method: 'get'
  })
}