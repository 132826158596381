import React from 'react'
import { message, Modal, Tag } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { dateTimeFormat } from 'utils/constant'

const { confirm } = Modal

export function errorSubmit(arr) {
  const messageArr = []
  arr.forEach(item => {
    item.errors.forEach(a => {
      messageArr.push(a)
    })
  })
  const messageString = messageArr.toString().replace(/,/gi, '</br>')
  let config = {
    content: <div className='multipleLineMessage' dangerouslySetInnerHTML={{ __html: messageString }} />
  }
  return message.error(config)
}

export function messageTips(messages, success = true) {
  let config = {
    content: Array.isArray(messages) ? <div className='multipleLineMessage'
      dangerouslySetInnerHTML={{ __html: messages.join('&').replace(/&/gi, '</br>') }} /> : messages,
    top: 100,
    duration: 2,
    maxCount: 3,
  }
  if (success) {
    return message.success(config)
  }
  return message.error(config)
}

export function confirmMessage(func, title = '是否删除?', cancelInfo = '已取消删除!', content = '', zIndex = 1000) {
  return confirm({
    icon: <ExclamationCircleOutlined />,
    title,
    zIndex,
    content,
    onOk() {
      func()
    },
    onCancel() {
      message.info(cancelInfo)
    },
  })
}

export function verifySampleFilesMessages(results) {
  Object.entries(results).forEach((item) => {
    let config = {
      content: item[0] + item[1].messages,
      top: 100,
      duration: 5
    }
    if (item[1].valid === 'success') {
      message.success(config)
    } else if (item[1].valid === 'warning') {
      message.warning(config)
    } else if (item[1].valid === 'error') {
      message.error(config)
      return false
    }
  })
}

export function beforeunloadFunction(e) {
  // Cancel the event
  e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
  // Chrome requires returnValue to be set
  e.returnValue = ''
}

export function localStorageControl(name, maxNum = 20, deleteNum = 10) {
  let value = JSON.parse(localStorage.getItem(name))
  if (value) {
    if (isObject(value)) {
      let keys = Object.keys(value)
      let len = keys.length
      let tempObj = { ...value }
      if (len === maxNum) {
        for (let i = 0; i < deleteNum; i++) {
          delete tempObj[keys[i]]
        }
        localStorage.setItem(name, JSON.stringify(tempObj))
      }
    }
  }
}

export function objectFromEntriesAlternative(tempArr) {
  if (Object.fromEntries !== undefined) {
    return Object.fromEntries(tempArr)
  } else {
    let tempObj = {}
    tempArr.forEach(item => {
      tempObj[item[0]] = item[1]
    })
    return tempObj
  }
}

export function momentObjectToString(obj) {
  let tempObj = { ...obj }
  Object.entries(obj).forEach(a => {
    console.log(a)
    if (a[1] && a[1]._isAMomentObject) {
      tempObj[a[0]] = tempObj[a[0]].format(dateTimeFormat)
    }
    if (Array.isArray(a[1]) && a[1].every(a => a._isAMomentObject)) {
      tempObj[`min_${a[0]}`] = a[1][0] ? a[1][0].format(dateTimeFormat) : ''
      tempObj[`max_${a[0]}`] = a[1][1] ? a[1][1].format(dateTimeFormat) : ''
      delete tempObj[a[0]]
    }
  })
  return tempObj
}

export function isObject(obj) {
  return obj != null && obj.constructor.name === "Object"
}

export function fileSizeConversion(size, num = 2) {
  if (size < 1024) {
    return `${size} byte`
  } else if (1024 <= size && size < 1048576) {
    return `${(size / 1024).toFixed(num)} KB`
  } else if (1048576 <= size && size < 1073741824) {
    return `${(size / 1048576).toFixed(num)} MB`
  } else if (1073741824 <= size && size < 1099511627776) {
    return `${(size / 1073741824).toFixed(num)} GB`
  } else if (1099511627776 < size) {
    return `${(size / 1099511627776).toFixed(num)} TB`
  }
}


export function bulkDeleteTableItems(selectedRowKeys, setSelectedRowKeys, currentPage, setCurrentPage, bulkDeleteFunc, getList) {
  let params, func
  params = {
    deleted_objects: selectedRowKeys
  }
  func = () => {
    bulkDeleteFunc(params).then(response => {
      if (currentPage === 1) {
        getList()
      } else {
        setCurrentPage(1)
      }
      setSelectedRowKeys([])
    })
  }
  confirmMessage(func, '是否批量删除？')
}

export function parameterDetailChange(val) {
  console.log(val)
  let tempObj = { ...val }
  let messageArr = []
  Object.entries(tempObj).map((item, index) => {
    /*eslint-disable-next-line*/
    return Object.entries(item[1]).map((itm, idx) => {
      let type = itm[1].type
      let label = itm[1].title
      // let desc = itm[1].desc
      let value = itm[1].value
      let range = itm[1].range
      let isInRange = true
      if (type === 'int' || type === 'float' || type === 'num') {
        if (range) {
          isInRange = range.some((a, i) => {
            console.log(a)
            console.log(Number(a.match(/([0-9]+)/g)[0]))
            console.log(Number(a.match(/([0-9]+)/g)[1]))
            return inRange(
              value,
              Number(a.match(/([0-9]+)/g)[0]),
              a.charAt(0) === '[',
              Number(a.match(/([0-9]+)/g)[1]),
              a.charAt(a.length - 1) === ']'
            )
          })
          if (!isInRange) {
            // message.error(`${ label }应在${ range }范围内`)
            messageArr.push(`${label}应在${range}范围内`)
          }
        }
      }
      itm[1].isInRange = isInRange
    })
  })
  console.log('parameterDetailChange: ', {
    detail: tempObj,
    messages: messageArr
  })
  // setPramDetail(tempObj)
  return {
    detail: tempObj,
    messages: messageArr
  }
}

export function inRange(x, min, minIncludes, max, maxIncludes) {
  if (minIncludes === false && maxIncludes === false) {
    return (x - min) * (x - max) <= 0
  } else if (minIncludes === false && maxIncludes === true) {
    if (x === min) {
      return false
    } else {
      return (x - min) * (x - max) <= 0
    }
  } else if (minIncludes === true && maxIncludes === true) {
    return (x - min) * (x - max) <= 0
  } else if (minIncludes === true && maxIncludes === false) {
    if (x === max) {
      return false
    } else {
      return (x - min) * (x - max) <= 0
    }
  }
}
export function frequencyCounter(search, arr) {
  let count = arr.reduce(function (n, val) {
    return n + (val === search)
  }, 0)
  return count
}
export function yesOrNoTag(text, record, index, yesText = '是', noText = '否') {
  let color = text ? 'green' : 'volcano'
  let txt = text ? yesText : noText
  return (
    <Tag color={color}>
      {txt}
    </Tag>
  )
}

/* eslint-disable */
export const validateMessages = {
  default: "Validation error on field '${name}'",
  required: "${label}为必填项!",
  enum: "'${name}' must be one of [${enum}]",
  whitespace: "${label}不能为空格",
  date: {
    format: "'${name}' is invalid for format date",
    parse: "'${name}' could not be parsed as date",
    invalid: "'${name}'不是合法日期",
  },

  types: {
    string: '${label}不是合法字符串!',
    method: '${label}不是合法字符串!',
    array: '${label}不是合法数组!',
    object: '${label}不是合法对象!',
    number: '${label}不是合法数字!',
    date: '${label}不是合法日期!',
    boolean: '${label}不是合法布尔值!',
    integer: '${label}不是合法整数!',
    float: '${label}不是合法浮点数!',
    regexp: '${label}不是合法正则表达式!',
    email: '${label}格式不合法!',
    url: '${label}不是合法网址!',
    hex: '${label}不是合法十六进制!',
  },
  string: {
    len: "'${name}'必须为${len}个字符",
    min: "'${name}'至少为${min}个字符",
    max: "'${name}'不能大于${max}个字符",
    range: "'${name}'必须在${min}到${max}个字符",
  },
  number: {
    len: "'${name}'须等于${len}",
    min: "'${name}'不得小于${min}",
    max: "'${name}'不得大于${max}",
    range: '${label}必须在${min}-${max}范围内',
  },
  array: {
    len: "'${name}'长度须为${len}",
    min: "'${name}'长度不小于${min}",
    max: "'${name}'长度不大于${max}",
    range: "'${name}'必须在${min}到${max}范围内",
  },
  pattern: {
    mismatch: "'${name}' does not match pattern ${pattern}",
  },
}
export const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}
export const tailLayout = {
  wrapperCol: { offset: 4, span: 20 }
}
// let baseUrl = 's-project-dev.iteralchemy.xyz/v1/api/'
let baseUrl = 'https://s.sunngs.com/v1/api/'  // 正式地址

// if (!window.location.origin.includes('localhost:')) {
//   baseUrl = window.location.origin + '/v1/api/'
// }

export { baseUrl }
