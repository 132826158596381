import React from 'react'
import { BackTop } from 'antd'
import HeaderTop from "../Header"
import { Route, Switch } from "react-router-dom"
import { routeComponents } from "../../../router"
import FooterBottom from "../Footer"

export default function Sidebar(props) {

  return <div className='rightContent'>
    <HeaderTop />
    <Switch>
      {routeComponents.map((item) => (
        <Route key={item.path} path={item.path} component={item.component} />
      ))}
    </Switch>

    <footer className={'footer'}>
      <FooterBottom />
    </footer>
    <BackTop />
  </div>
}
