import { createStore } from 'redux'

const authInfo = JSON.parse(localStorage.getItem('authInfo'))
let token = null
if (authInfo) {
  token = authInfo.token
}
/**
 * This is a reducer, a pure function with (state, action) => state signature.
 * It describes how an action transforms the state into the next state.
 *
 * The shape of the state is up to you: it can be a primitive, an array, an object,
 * or even an Immutable.js data structure. The only important part is that you should
 * not mutate the state object, but return a new object if the state changes.
 *
 * In this example, we use a `switch` statement and strings, but you can use a helper that
 * follows a different convention (such as function maps) if it makes sense for your
 * project.
 */
let defaultState = {
  isAuthenticated: token !== null,
  currentLocation: ''
}


const store = createStore(function (state = defaultState, action = {}) {
  const { type, param } = action
  switch (type) {
    case 'authenticate':
      return { ...state, isAuthenticated: true }
    case 'location':
      return { ...state, currentLocation: { param } }
    default:
      return { ...state, defaultState }
  }
})

// store.subscribe(() => {
//   let storeObj = store.getState()
//   console.log(storeObj)
// })

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.

// You can use subscribe() to update the UI in response to state changes.
// Normally you'd use a view binding library (e.g. React Redux) rather than subscribe() directly.
// However it can also be handy to persist the current state in the localStorage.
// The only way to mutate the internal state is to dispatch an action.
// The actions can be serialized, logged or stored and later replayed.
export default store
