/* eslint-disable */
import React from 'react'

import { Form, Input, Button } from 'antd'

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formRef: React.createRef()
    }
  }
  componentDidMount() {
    this.state.formRef.current.setFieldsValue(this.props.form)
  }


  onFinish = values => {
    this.props.submitForm(values)
  };

  render() {
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    }
    const tailLayout = {
      wrapperCol: { offset: 4, span: 20 },
    }
    const validateMessages = {
      required: '${label}为必填项!',
      types: {
        email: '${label}格式不合法!',
        number: '${label}不是合法数字!',
      },
      number: {
        range: '${label}必须在${min}-${max}范围内',
      },
    }
    return <Form
      {...layout}
      ref={this.state.formRef}
      onFinish={this.onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        label="新密码"
        name='password'
        rules={[{
          required: true,
          message: '请填写!',
        }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" className={'mR15'}>
          {this.props.name === 'resetPassword' ? "重置密码" : "修改"}
        </Button>
        <Button onClick={this.props.cancelForm}>
          取消
        </Button>
      </Form.Item>
    </Form>

  }
}
