/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button, Table, Modal, Form, Input, Select, InputNumber, message } from 'antd'

import SearchWithWrapper from 'components/lg/search'
import EvenPagination from 'components/lg/pagination'

import {
  fetchSamplesList,
  fetchSampleDetail,
  modifySample,
  putInStorage,
} from 'api/sample'

import './index.scss'
import { fetchProducts } from 'api/product'
import { confirmMessage } from 'utils'

const { Option } = Select

export default function (props) {

  const [currentTableName] = useState('samples')

  const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false)

  const [isTableLoading, setIsIsTableLoading] = useState(false)
  const [table, setTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [allCount, setAllCount] = useState(0)
  const [currentId, setCurrentId] = useState(null)
  const [searchTxt, setSearchTxt] = useState('')
  const [selectedStatuses, setSelectedStatuses] = useState('')
  const [searchForm, setSearchForm] = useState({})

  const [modalVisible, setModalVisible] = useState(false)

  const [evenModalVisible, setEvenModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [modalClosable, setModalClosable] = useState(true)
  const [modalMaskClosable, setModalMaskClosable] = useState(true)
  const [modalMaskShow, setModalMaskShow] = useState(true)
  const [products, setProducts] = useState([])
  const [clientPhone, setClientPhone] = useState('')
  const [modalZIndex, setModalZIndex] = useState(1000)

  // selected by default
  let samplesSelectedKeys = []
  let samplesSelectedRows = []
  const [selectedRowKeys, setSelectedRowKeys] = useState(samplesSelectedKeys)
  const [selectedRows, setSelectedRows] = useState(samplesSelectedRows)

  const [form] = Form.useForm()

  useEffect(() => {
    getList()
    fetchProducts().then(({ data }) => setProducts(data.results))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchTxt, selectedStatuses])


  const listQuery = () => {
    let tempObj = {
      page: `${currentPage}`,
      page_size: `${pageSize}`,
      search: `${searchTxt}`,
      status: selectedStatuses
    }
    if (searchForm === {}) {
      return tempObj
    } else {
      return { ...tempObj, ...searchForm }
    }
  }

  const getList = async () => {
    setIsIsTableLoading(true)
    const whichStatus = status => {
      switch (status) {
        case 10:
          return '已绑定'
        case 20:
          return '待审核'
        case 30:
          return '已完成'
        default:
      }
    }
    fetchSamplesList(listQuery()).then(({ data }) => {
      const { results } = data
      for (const iterator of results) {
        iterator.statusTxt = whichStatus(iterator.status)
      }
      setTable(data.results)
      setAllCount(data.count)
      setIsIsTableLoading(false)

      if (isSearchBtnLoading)
        setIsSearchBtnLoading(false)
    })
  }


  const getDetail = (id, which, record) => {
    if (which === '详情') {
      setClientPhone(record.client_phone_number)
      initModal()
      setCurrentId(id)
      setModalTitle(which)
      fetchSampleDetail(id).then(({ data }) => form.setFieldsValue(data))
      setModalVisible(true)
      return
    }
    const handleStorage = () => {
      putInStorage(id, record.status).then(({ data }) => {
        getList()
        message.success(data)
      })
    }
    confirmMessage(handleStorage, '确定入库？')
  }

  const onSearchChange = (value) => {
    setIsSearchBtnLoading(true)
    if (value === searchTxt) {
      setCurrentPage(1)
      getList().then(() => {
        setIsSearchBtnLoading(false)
      })
    } else {
      setCurrentPage(1)
      setSearchTxt(value)
    }
  }
  const onShowSizeChange = (name, current, pageSize) => {
    setCurrentPage(1)
    setPageSize(pageSize)
  }
  const onPageChange = (name, page, pageSize) => {
    setCurrentPage(page)
  }
  const initModal = () => {
    setModalClosable(true)
    setModalMaskClosable(true)
    setModalMaskShow(true)
    setModalZIndex(1000)
  }
  const cancelModal = () => {
    setModalVisible(false)
    setEvenModalVisible(false)
  }
  const submitModal = () => {
    setModalVisible(false)
    getList()
  }

  const onTableSelect = (record, selected) => {
    let id = record.id
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, id]
      tempRowsArr = [...selectedRows, record]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => a !== id)
      tempRowsArr = selectedRows.filter(a => a.id !== id)
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)

  }
  const onTableSelectAll = (selected, selectedRowsWithBug, changeRows) => {
    let idArr = changeRows.map(a => a.id)
    let tempKeysArr, tempRowsArr
    if (selected) {
      tempKeysArr = [...selectedRowKeys, ...idArr]
      tempRowsArr = [...selectedRows, ...changeRows]
    } else {
      tempKeysArr = selectedRowKeys.filter(a => !idArr.includes(a))
      tempRowsArr = selectedRows.filter(a => !idArr.includes(a.id))
    }
    setSelectedRowKeys(tempKeysArr)
    setSelectedRows(tempRowsArr)
  }
  const handleTableChange = (pagination, filters, sorter, tableName) => {
    const { statusTxt } = filters
    let selectedStatuses = ''
    if (statusTxt)
      selectedStatuses = filters.statusTxt.join()
    setSelectedStatuses(selectedStatuses)
  }

  const handleSubmit = v => {
    modifySample(currentId, v).then(({ data }) => {
      message.success(data)
      getList()
      setModalVisible(false)
    })
  }
  const rowClassName = (record, index) => {
    if (record.is_deleted) {
      return 'highlight-info'
    } else {
      return ''
    }
  }

  let filters = [
    { text: '已绑定', value: 10 },
    { text: '待审核', value: 20 },
    { text: '已审核', value: 25 },
    { text: '已完成', value: 30 },
  ]
  let columns = [
    // { title: '订单编号', dataIndex: 'number' },
    { title: '条码号', dataIndex: 'serial_number' },
    { title: '检测套餐', dataIndex: 'name' },
    { title: '受检者', dataIndex: 'nickname' },
    { title: '性别', dataIndex: 'gender' },
    { title: '年龄', dataIndex: 'age' },
    { title: '客户电话', dataIndex: 'client_phone_number' },
    {
      title: '状态',
      dataIndex: 'statusTxt',
      filters
    },
    // { title: '检测结果', dataIndex: 'result' },
    {
      title: '操作',
      dataIndex: 'id',
      render: (text, record) => (
        <div className='operation-column'>
          <a onClick={() => getDetail(text, '详情', record)}>详情</a>
          {record.status === 10 && <a onClick={() => getDetail(text, '入库', record)}>入库</a>}
        </div>
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    /*preserveSelectedRowKeys: true,*/
    onSelect: onTableSelect,
    onSelectAll: onTableSelectAll,
  }

  return <div className='mainWrapper detection-root'>
    <SearchWithWrapper
      loading={isSearchBtnLoading}
      value={searchTxt}
      onChange={onSearchChange}
    />

    <Table
      loading={isTableLoading}
      rowKey='id'
      dataSource={table}
      columns={columns}
      pagination={false}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      onChange={(pagination, filters, sorter) =>
        handleTableChange(pagination, filters, sorter, 'samples')}
      scroll={{ x: 1300 }}
    />
    <EvenPagination current={currentPage}
      total={allCount}
      disabled={isTableLoading}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}
      name={currentTableName}>
    </EvenPagination>


    <Modal
      width='50%'
      title={modalTitle}
      visible={modalVisible}
      closable={modalClosable}
      maskClosable={modalMaskClosable}
      mask={modalMaskShow}
      footer={null}
      onCancel={cancelModal}
    >
      <div className='detail'>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={v => handleSubmit(v)}
        >
          <Form.Item
            label='客户电话'
          >
            <Input disabled value={clientPhone} />
          </Form.Item>
          <Form.Item
            label='条码号'
            name='serial_number'
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='检测套餐'
            name='product_id'
            rules={[{ required: true }]}
          >
            <Select>
              {products.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            label='检测人'
            name='nickname'
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='性别'
            name='gender'
            rules={[{ required: true }]}
          >
            <Select>
              <Option value='男'>男</Option>
              <Option value='女'>女</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label='年龄'
            name='age'
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </div>
}
