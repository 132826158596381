import React from 'react'
import { Input } from 'antd'

import './index.scss'

const { Search } = Input

export default function (props) {

  const onSearch = (value) => {
    props.onChange(value)
  }
  const onChange = (e) => {
    // props.onChange(e.target.value)
  }

  return <div className='flexWrap spaceBetween searchWrapper'>
    <div className='children'>
      {props.slot || props.children}
    </div>
    <Search
      placeholder="请输入内容"
      onSearch={value => onSearch(value)}
      onChange={onChange}
      enterButton
      allowClear
      style={{ width: '30%' }}
      loading={props.loading}
    />
  </div>
}