import React, { useEffect } from 'react'
import { Modal } from 'antd'

export default function AtdModal(props) {

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOk = () => {
    setTimeout(() => {
      // setState({ loading: false, visible: false });
      props.submit()
    }, 1000)
  }

  const handleCancel = () => {
    // setState({ visible: false });
    props.cancel()
  }

  return <Modal
    getContainer={false}
    destroyOnClose={true}
    visible={props.visible}
    title={props.title}
    width={props.width || '70%'}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={null}
    closable={props.closable || true}
    zIndex={props.zIndex || 1000}
    maskClosable={props.maskClosable === false ? props.maskClosable : true}
    mask={props.mask === false ? props.mask : true}
    style={props.style}
  >
    <React.Fragment>
      {props.slot || props.children}
    </React.Fragment>
  </Modal>
}
